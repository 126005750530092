export function FontSelector(p) {


    const props = {
        ...{
            options: [{ meta: { fullName: 'Arial' } }],
            preview: false
        },
        ...p
    }

    const el = new SELECT('andy-ui-select');

    function constructor() {

        if (andy.fonts) {
            el.addEventListener('change', () => andy.fonts.appendByFontFamily(el.value));

            let contentFonts = andy.fonts.getFontsAsArray();
            contentFonts.forEach(font => {
                props.options.push(font);
            });
        }

        props.options.forEach(font => {
            addOption(font.meta.fullName, font.meta.fullName);
        });
    }

    function addOption(key, value) {
        const optionEl = new OPTION('', key, value);
        el.append(optionEl);
    }

    function getElement() {
        return el;
    }

    function getValue() {
        return el.value;
    }

    function setValue(value) {
        el.value = value;
    }

    constructor();

    return {
        get element() {
            return el;
        },
        getElement,
        getValue,
        setValue
    }

}