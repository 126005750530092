require('./../../../studiov2/main');

import { StudioProjectData } from "./studio.project";
import { StudioContainer } from "./views/studio.container";
import { ProjectSettingsModal } from "./views/project.settings.modal";
import { EditorToolbars } from "./views/editor/editor.toolbars";
import { PageSettingsModal } from "./views/editor/page.settings.modal";
import { NewPageModal } from "./views/editor/new.page.modal";
import { SchedulerView } from "./views/scheduler/scheduler.view";
import { SchedulerToolbars } from "./views/scheduler/scheduler.toolbars";
import { LogicView } from "./views/logic/logic.view";
import { LogicToolbars } from "./views/logic/logic.toolbars";
import { EventSelectorModal } from "./views/logic/event.selector.modal";
import { PagesSidebar } from "./views/pages.sidebar";
import { SequencesSidebar } from "./views/scheduler/sequences.sidebar";
import { SequenceModal } from "./views/scheduler/sequence.modal";
import { EventModal } from "./views/scheduler/event.modal";
import { SaveProjectModal } from "./views/save.project.modal";
import { DeleteModal } from "./../common/views/delete.modal";



export function StudioController() {

    window.andyStudio = new AndyStudio();
    const studioContainer = new StudioContainer();

    const projectData = new StudioProjectData();

    const projectSettingsModal = new ProjectSettingsModal();

    const editorContainer = studioContainer.container.newView({ code: 'editor.view', route: '/studio/editor' });
    const editorToolbars = new EditorToolbars();
    const pageSettingsModal = new PageSettingsModal();
    const newPageModal = new NewPageModal();

    const schedulerContainer = studioContainer.container.newView({ code: 'scheduler.view', route: '/studio/scheduler' });
    const schedulerToolbars = new SchedulerToolbars();
    const schedulerView = new SchedulerView();

    const logicContainer = studioContainer.container.newView({ code: 'logic.view', route: '/studio/logic' });
    const logicToolbars = new LogicToolbars();
    const logicView = new LogicView();
    const eventSelectorModal = new EventSelectorModal();

    const pagesSidebar = new PagesSidebar();
    const sequencesSidebar = new SequencesSidebar();
    const sequenceModal = new SequenceModal();
    const eventModal = new EventModal();

    const sequenceDeleteModal = new DeleteModal();
    const pageDeleteModal = new DeleteModal();

    const saveProjectModal = new SaveProjectModal();

    let currentPage = null;
    let currentDragPage = null;

    function constructor() {

        //Studio
        studioContainer.on('studio.exit', exitStudio);
        studioContainer.on('project.settings', () => projectSettingsModal.show(projectData.data));
        studioContainer.on('project.save', saveProjectModal.show);

        projectSettingsModal.on('save', saveProjectSettingsHandler);

        saveProjectModal.on('save', saveProject);

        //Editor
        andyStudio.render(editorContainer.element);
        editorToolbars.on('page.settings', openPageSettingsHandler);
        editorToolbars.on('editor.zoomchange', zoomChangeHandler);
        editorToolbars.on('editor.zoomfit', zoomAutoScaleHandler);
        editorToolbars.on('editor.undo', andyStudio.actions.undo);
        editorToolbars.on('editor.redo', andyStudio.actions.redo);
        editorToolbars.on('editor.selectall', andyStudio.actions.selectAll);
        editorToolbars.on('editor.group', andyStudio.actions.group);
        editorToolbars.on('editor.guidelines', andyStudio.rulers.toggleGuidelines);
        editorToolbars.on('editor.rulers', andyStudio.rulers.toggleRulers);
        editorToolbars.on('editor.animations', andyStudio.toggleAnimationToolbar);
        pageSettingsModal.on('save', savePageSettingsHandler);

        projectData.on('data', projectDataHandler);

        showEditor();

        //Scheduler
        schedulerContainer.append(schedulerView.element);
        schedulerToolbars.on('calendar.week', schedulerView.calendar.weekView);
        schedulerToolbars.on('calendar.month', schedulerView.calendar.monthView);
        schedulerToolbars.on('calendar.list', schedulerView.calendar.listView);
        schedulerToolbars.on('calendar.today', schedulerView.calendar.today);
        schedulerToolbars.on('calendar.previous', schedulerView.calendar.prev);
        schedulerToolbars.on('calendar.next', schedulerView.calendar.next);
        // schedulerToolbars.on('calendar.add', addScheduleEventHandler);
        schedulerView.on('date.range.change', schedulerToolbars.setCurrentDateRange);
        eventModal.on('save', saveEventHandler);
        eventModal.on('delete', deleteEventHandler);

        //Logic

        logicContainer.append(logicView.element);
        logicToolbars.on('logic.events', eventSelectorModal.show);
        eventSelectorModal.on('add', addEventHandler);


        //Menu 

        studioContainer.on('menu.editor', showEditor);
        studioContainer.on('menu.scheduler', showScheduler);
        studioContainer.on('menu.logic', showLogic);


        //PagesSidebar
        pagesSidebar.on('new.page', () => newPageModal.show({ name: 'page ' + Object.values(projectData.pages).length }));

        pagesSidebar.on('open.page', (page) => openPage(page._id));
        pagesSidebar.on('drag.page', (page) => pageDragHandler(page._id));

        pagesSidebar.on('duplicate.page', (page) => duplicatePageHandler(page._id));
        pagesSidebar.on('remove.page', (page) => pageDeleteModal.show(page.name, page));

        pageDeleteModal.on('yes', pageDeleteHandler);

        //New page
        newPageModal.on('new.page', createPageHandler);

        projectData.on('pages', updateViews);

        logicView.on('drop', pageDropHandler);

        // Sequences Sidebar

        sequencesSidebar.on('sequence.new', () => {
            sequenceModal.pages = projectData.pages;
            sequenceModal.show({ name: 'sequence ' + Object.values(projectData.timelines).length })
        });


        sequencesSidebar.on('sequence.open', (sequence) => {
            sequenceModal.pages = projectData.pages;
            sequenceModal.show(sequence)
        });

        sequencesSidebar.on('sequence.duplicate', duplicateSequenceHandler);

        sequencesSidebar.on('sequence.remove', (sequence) => sequenceDeleteModal.show(sequence.name, sequence));

        schedulerView.calendar.on('event.drop', (info) => {
            console.log('eventdrop', info);
            updateEventHandler(info.event);
        });

        schedulerView.calendar.on('event.receive', (info) => {
            console.log('event receive', info);
            createEventHandler(info.event);
        });

        // schedulerView.calendar.on('external.drop', (info)=>{
        //     console.log('drop', info);
        // });

        schedulerView.calendar.on('event.click', (info) => {
            console.log('click', info);
            editEventHandler(info.event);
        });

        sequenceModal.on('save', sequenceSaveHandler);

        sequenceDeleteModal.on('yes', sequenceDeleteHandler);

        setTimeout(() => {
            andyStudio.Parsers.modulesFromV1(andy.modules.getModulesFromV1());
        }, 1500);

    }

    // function addScheduleEventHandler() {
    //     schedulerView.calendar.addEvent({
    //         id: andy.uid.generate(),
    //         title: 'teste',
    //         start: '2020-09-17',
    //         editable: true
    //     })
    // }

    function projectDataHandler(data) {
        logicView.parseApplicationStructure(projectData.data);
        loadScheduleEvents(projectData.data);
        schedulerView.calendar.today();
        updateViews();
        if (Object.values(projectData.pages).length) {
            openPage();
        }
    }

    function zoomChangeHandler(val) {
        andyStudio.studio.scale = val * 0.01;
        editorToolbars.setZoomValue(andyStudio.studio.scale);
    }

    function zoomAutoScaleHandler() {
        andyStudio.stage.autoScale();
        editorToolbars.setZoomValue(andyStudio.studio.scale);
    }

    function updateViews(data) {
        studioContainer.projectName = projectData.data.name;
        pagesSidebar.setPages(projectData.pages);
        sequencesSidebar.setSequences(projectData.timelines, schedulerView.calendar);
    }

    function pageDropHandler(e) {
        if (currentDragPage) {
            logicView.createNodeFromPage(projectData.pages[currentDragPage]);
            currentDragPage = null;
        }
    }

    function deleteEventHandler(event) {
        const calendarEvent = schedulerView.calendar.getEventById(event._id);
        if (calendarEvent) {
            calendarEvent.remove();
        }
        projectData.schedule[event._id] = null;
        delete projectData.schedule[event._id];
        updateViews();
        eventModal.hide();
    }

    function saveEventHandler(event) {

        projectData.schedule[event._id] = {
            ...projectData.schedule[event._id],
            ...event
        }

        event = projectData.schedule[event._id];

        addEventToCalendar(event);

    }

    function addEventToCalendar(event) {

        const calendarEvent = schedulerView.calendar.getEventById(event._id);
        if (calendarEvent) {
            calendarEvent.remove();
        }

        const sequence = projectData.timelines[event.sequence];

        if (event.recurrent) {

            let weekDays = [];

            if (event.weekdays) {
                if (event.weekdays.monday) weekDays.push('mo');
                if (event.weekdays.tuesday) weekDays.push('tu');
                if (event.weekdays.wednesday) weekDays.push('we');
                if (event.weekdays.thursday) weekDays.push('th');
                if (event.weekdays.friday) weekDays.push('fr');
                if (event.weekdays.saturday) weekDays.push('sa');
                if (event.weekdays.sunday) weekDays.push('su');
            }

            let months = [];

            if (event.months) {
                if (event.months.jan) months.push(1);
                if (event.months.feb) months.push(2);
                if (event.months.mar) months.push(3);
                if (event.months.apr) months.push(4);
                if (event.months.may) months.push(5);
                if (event.months.jun) months.push(6);
                if (event.months.jul) months.push(7);
                if (event.months.aug) months.push(8);
                if (event.months.sep) months.push(9);
                if (event.months.oct) months.push(10);
                if (event.months.nov) months.push(11);
                if (event.months.dec) months.push(12);
            }

            const monthdays = (event.monthdays) ? event.monthdays.split('') : [];

            let rrule = {
                dtstart: event.dtstart,
                until: event.until,
                freq: event.frequency,
                interval: event.interval,
                count: (event.count) ? event.count : undefined,
                byweekday: (weekDays.length) ? weekDays : undefined,
                bymonth: (months.length) ? months : undefined,
                wkst: (event.weekstart !== 'mo') ? event.weekstart : undefined,
                bymonthday: (monthdays.length) ? monthdays : undefined
            }

            console.log(rrule);

            schedulerView.calendar.addEvent({
                title: sequence.name,
                allDay: event.allDay,
                id: event._id,
                groupId: event.groupId,
                backgroundColor: sequence.color,
                rrule,
                duration: (event.allDay) ? undefined : {
                    hours: event.duration.h,
                    minutes: event.duration.m,
                    seconds: event.duration.s
                }
            });

        } else {
            schedulerView.calendar.addEvent({
                title: sequence.name,
                id: event._id,
                start: event.start,
                end: event.end,
                allDay: event.allDay,
                backgroundColor: sequence.color
            });
        }

        schedulerView.calendar.render();
    }

    function editEventHandler(event) {
        eventModal.sequences = projectData.timelines;
        const schedule = projectData.schedule[event.id];
        eventModal.show(schedule);
    }

    function addEventHandler(event) {
        logicView.createEventNode(event, event);
        eventSelectorModal.hide();
    }

    function createEventHandler(event) {
        projectData.schedule[event.id] = {
            _id: event.id,
            allDay: event.allDay,
            start: event.start,
            end: event.end,
            startTime: null,
            endTime: null,
            recurrent: false,
            dtstart: null,
            until: null,
            duration: {},
            frequency: 'yearly',
            interval: 1,
            count: '',
            weekstart: 'mo',
            weekdays: {},
            months: {},
            monthdays: '',
            groupId: andy.uid.generate(),
            sequence: event.extendedProps.sequence._id
        }
    }

    function deleteCalendarEvents() {
        schedulerView.calendar.clear();
    }

    function updateEventHandler(event) {
        const { allDay, end, start } = event;
        projectData.schedule[event.id] = {
            ...projectData.schedule[event.id],
            allDay,
            end,
            start
        }
    }

    function pageDragHandler(pageID) {
        currentDragPage = pageID;
    }

    function pageDeleteHandler(page) {
        projectData.pages[page._id] = null;
        delete projectData.pages[page._id];
        updateViews();
        pageDeleteModal.hide();
    }

    function duplicatePageHandler(pageID) {
        const uid = andy.uid.generate();
        const duplicate = {...projectData.pages[pageID], name: projectData.pages[pageID].name + ' (copy)', code: uid, _id: uid };
        projectData.pages[uid] = duplicate;
        updateViews();
    }

    function saveProjectSettingsHandler(project) {
        projectData.data.name = project.name;
        projectData.data.description = project.description;
        projectSettingsModal.hide();
        updateViews();
    }


    function sequenceDeleteHandler(sequence) {
        projectData.timelines[sequence._id] = null;
        delete projectData.timelines[sequence._id];
        updateViews();
        sequenceDeleteModal.hide();
    }

    function duplicateSequenceHandler(sequence) {
        const uid = andy.uid.generate();
        const duplicate = {...projectData.timelines[sequence._id], name: projectData.timelines[sequence._id].name + ' (copy)', code: uid, _id: uid };
        projectData.timelines[uid] = duplicate;
        updateViews();
    }

    function sequenceSaveHandler(sequence) {
        // console.log('save', sequence);
        if (sequence._id) {
            projectData.timelines[sequence._id] = {...sequence };
        } else {
            let uid = andy.uid.generate();
            projectData.timelines[uid] = { _id: uid, ...sequence };
        }
        sequenceModal.hide();
        updateViews();
    }

    function openPageSettingsHandler() {
        pageSettingsModal.show(projectData.pages[currentPage]);
    }

    function savePageSettingsHandler(page) {
        projectData.pages[currentPage].name = page.name;
        projectData.pages[currentPage].description = page.description;
        projectData.pages[currentPage].code = page.code;
        projectData.pages[currentPage].studio.size.w = parseInt(page.width);
        projectData.pages[currentPage].studio.size.h = parseInt(page.height);
        projectData.pages[currentPage].studio.backgroundColor = page.backgroundColor;
        projectData.pages[currentPage].masterPage = page.masterPage;
        pageSettingsModal.hide();
        updateViews();
        andyStudio.studio.setSize(page.width, page.height);
        andyStudio.studio.backgroundColor = page.backgroundColor;
    }

    function resizePageWithOrientation(w, h, orientation) {
        if (orientation == 'horizontal') {
            if (w > h) {
                return { w, h }
            } else {
                return { w: h, h: w }
            }
        } else {
            if (w > h) {
                return { w: h, h: w }
            } else {
                return { w, h }
            }
        }
    }

    function createPageHandler(page) {

        let uid = andy.uid.generate();
        let pageSize = { w: 0, h: 0 };
        if (page.resolution.custom) {
            pageSize = { w: page.width, h: page.height };
        } else {
            pageSize = pageSize = resizePageWithOrientation(parseInt(page.resolution.width), parseInt(page.resolution.height), page.orientation);
        }
        projectData.pages[uid] = { name: page.name, description: page.description, code: andy.uid.generate(), masterPage: false, studio: { size: pageSize, blocks: [], guidelines: [], backgroundColor: '#FFF' }, _id: uid };
        updateViews();
        newPageModal.dismissable(true);
        newPageModal.hide();
        openPage(uid);
    }

    function showEditor() {
        studioContainer.clearToolbars();
        studioContainer.clearSidebarListSection();
        studioContainer.sidebarSection.append(pagesSidebar.element);
        pagesSidebar.editorMode();
        studioContainer.leftToolbar.append(editorToolbars.elements[0]);
        studioContainer.rightToolbar.append(editorToolbars.elements[1]);
        editorContainer.show();
    }

    function showScheduler() {
        studioContainer.clearToolbars();
        studioContainer.clearSidebarListSection();
        studioContainer.sidebarSection.append(sequencesSidebar.element);
        studioContainer.leftToolbar.append(schedulerToolbars.elements[0]);
        studioContainer.rightToolbar.append(schedulerToolbars.elements[1]);
        schedulerContainer.show();
        schedulerView.update();
    }

    function showLogic() {
        studioContainer.clearToolbars();
        studioContainer.clearSidebarListSection();
        pagesSidebar.logicMode();
        studioContainer.sidebarSection.append(pagesSidebar.element);
        studioContainer.leftToolbar.append(logicToolbars.elements[0]);
        studioContainer.rightToolbar.append(logicToolbars.elements[1]);
        logicContainer.show();
        logicView.fixRenderPaths();
    }

    function exitStudio() {
        close();
        showEditor();
    }

    function saveProject() {
        commitCurrentPage();
        commitCurrentLogic();
        commitCurrentSchedulle();
        projectData.save(() => {
            saveProjectModal.hide();
        })
    }

    function commitCurrentPage() {
        if (currentPage) {
            projectData.pages[currentPage].studio = andyStudio.data;
        }
    }

    function commitCurrentLogic() {
        console.log(logicView.structure);
        projectData.structure = logicView.structure;
    }

    function commitCurrentSchedulle() {

    }

    function loadScheduleEvents(data) {
        Object.values(data.schedule).forEach(event => addEventToCalendar(event));
    }

    function open(project) {
        console.log(project);
        projectData.clear();

        if (project) {
            try {
                studioContainer.show();
                projectData.data = project;
            } catch (e) {
                console.error("Broken Project", e);
                exitStudio();
            }
        } else {
            console.error('Please provide a project');
        }

    }

    function openPage(uid) {
        commitCurrentPage();
        if (!uid) {
            uid = Object.keys(projectData.pages)[0];
        }
        currentPage = uid;
        andyStudio.reset();
        andyStudio.data = projectData.pages[uid].studio;

        andyStudio.init();
        setTimeout(zoomAutoScaleHandler, 100);

    }

    function close() {
        logicView.clear();
        studioContainer.hide();
        andyStudio.reset();
        deleteCalendarEvents();
        currentPage = null;
        projectData.clear();
    }

    function newProject({ name, description }) {
        studioContainer.show();
        projectData.clear();
        projectData.data = { name, description };
        logicView.parseApplicationStructure(projectData.data);
        studioContainer.show();
        newPageModal.dismissable(false);
        newPageModal.show({ name: 'page ' + Object.values(projectData.pages).length });
        schedulerView.calendar.today();
        andyStudio.stage.autoScale();
    }

    function importProject(project) {
        open(project);
    }


    constructor();

    return {
        open,
        close,
        newProject,
        importProject
    }
}