export function VideoEditor() {

    let value = null;
    let events = new andy.EventManager();
    let el = new DIV('video-editor');
    let videoEl = new VIDEO('video', '', true);
    let buttonGroup = new andy.UI.Group({
        createTrigger: new andy.UI.Button({ label: 'create_trigger_at_current_time', icon: 'mdi-card-plus' }, createTrigger),
        clearAll: new andy.UI.Button({ label: 'clear_all_triggers', icon: 'mdi-delete-sweep'}, clearAllTriggers)
    });

    el.append(videoEl, buttonGroup.getElement());

    function getElement() {
        return el;
    }

    function getValue() {
        return value;
    }

    function setValue(v) {
        value = v;
        videoEl.src = `${andy.backend.location}/data/${v.filename}`;
        /* console.log('aqui', v); */
    }

    function createTrigger() {
        events.dispatchEvent('create-trigger', { timestamp: videoEl.currentTime});
    }

    function clearAllTriggers(){
        events.dispatchEvent('clear-all-triggers');
    }

    let _public = {
        getElement,
        getValue,
        setValue,
        on: events.addEventListener
    }

    andy.UI._instances.push(_public);
    return _public;
}