export function ListDevicesView() {

    const events = new andy.EventManager();

    let devicesForms, devicesGridList;

    function constructor() {

        const sortByDropdown = {
            nameAsc: new andy.UI.Button({ label: 'A-Z', icon: 'mdi-sort-alphabetical-ascending' }, () => devicesGridList.sortItems('nameAscending')),
            nameDesc: new andy.UI.Button({ label: 'Z-A', icon: 'mdi-sort-alphabetical-descending' }, () => devicesGridList.sortItems('nameDescending'))
        };

        devicesGridList = new andy.UI.GridList({
            template: deviceTemplate,
            groupFunction: item => item.group || 'no_group',
            groupTemplate,
            sort: [
                { label: 'nameAscending', sort: sortAsc },
                { label: 'nameDescending', sort: sortDesc }
            ]
        });

        devicesForms = new andy.UI.Form('devices', {
            grid: { item: devicesGridList }
        }, {
            create: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, () => events.dispatchEvent('add'))
        }, {
            search: new andy.UI.RetroSearchBox(devicesGridList, { key: 'name' }),
            filter: new andy.UI.DropDown({ icon: 'mdi-sort', label: 'sort_by', items: sortByDropdown })
        });
    }

    function sortAsc(a, b) {
        if (a.item.name < b.item.name) {
            return -1;
        }
        if (a.item.name > b.item.name) {
            return 1;
        }
        return 0;
    }

    function sortDesc(a, b) {
        if (a.item.name < b.item.name) {
            return 1;
        }
        if (a.item.name > b.item.name) {
            return -1;
        }
        return 0;
    }

    function deviceTemplate(item, render) {

        const wrapper = new DIV('item');

        const statusBtn = new DIV(`connection ${(item.session.connected) ? 'online' : 'offline'}`);

        const labelEl = new andy.UI.Label((item.name) ? item.name : item.macAddr).getElement();
        const icon = new andy.UI.Icon('mdi-monitor').getElement();

        const moreDropdownItems = {
            group: new andy.UI.Button({ label: 'change_group', icon: 'mdi-tag-multiple-outline' }, () => events.dispatchEvent('group.device', item)),
            /* resume: new andy.UI.Button({ label: 'resume', icon: 'mdi-play' }, () => events.dispatchEvent('resume.device', item)),
            pause: new andy.UI.Button({ label: 'pause', icon: 'mdi-pause' }, () => events.dispatchEvent('pause.device', item)),
            sync: new andy.UI.Button({ label: 'sync', icon: 'mdi-cloud-sync-outline' }, () => events.dispatchEvent('sync.device', item)),
            refresh: new andy.UI.Button({ label: 'refresh', icon: 'mdi-refresh' }, () => events.dispatchEvent('refresh.device', item)),
            reboot: new andy.UI.Button({ label: 'reboot', icon: 'mdi-restart' }, () => events.dispatchEvent('reboot.device', item)),
            poweroff: new andy.UI.Button({ label: 'power_off', icon: 'mdi-power' }, () => events.dispatchEvent('poweroff.device', item)), */
            remove: new andy.UI.Button({ label: 'remove', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('remove.device', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        wrapper.append(statusBtn, icon, labelEl, moreDropdown.getElement());

        wrapper.addEventListener('click', () => {
            events.dispatchEvent('device.open', item);
        });

        if (item.enabled) {
            render(wrapper);
        }

        return item;
    }

    function groupTemplate(code, wrapper) {
        const label = new andy.UI.Label(code)
        const element = new DIV('content');

        const groupUI = new andy.UI.Group({ label }, { style: { justifyContent: 'flex-start', position: 'relative' } });

        const header = new DIV('header');
        header.append(groupUI.getElement());

        let groupWrapper = new DIV('group-wrapper');
        groupWrapper.append(header, element);
        wrapper.append(groupWrapper);

        return { code, label, element };
    }

    constructor();

    return {
        get element() {
            return devicesForms.element;
        },
        get gridList() {
            return devicesGridList;
        },
        on: events.addEventListener
    }
}