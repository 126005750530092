export function LiveScreen() {

    var scope = this,
        stop = true,
        screen = new DIV('live-screen');

    const events = new andy.EventManager();
    const dimensions = { width: 0, height: 0 };
    const imgDimensions = { width: 0, height: 0 };

    let parent = screen.parentElement;

    const pictureBtn = new andy.UI.Button({ icon: 'mdi-camera' }, downloadPictureHandler);
    pictureBtn.getElement().style.cssText = "max-width: 30px; max-height: 30px; width: 100%; position: absolute; left: 12px; top: 12px; height: 100%;";

    const remoteBtn = new andy.UI.Button({ icon: 'mdi-remote' }, openRemoteConnectionHandler);
    remoteBtn.getElement().style.cssText = "max-width: 30px; max-height: 30px; width: 100%; position: absolute; left: 48px; top: 12px; height: 100%;";

    screen.append(pictureBtn.getElement(), remoteBtn.getElement());

    this.rate = 10;
    this.quality = 20;
    this.modal = new ScreenModal();

    this.update = (buffer) => {
        screen.style.backgroundSize = "contain";
        screen.style.backgroundImage = "url('" + 'data:image/jpeg;base64,' + buffer + "')";

        const i = new Image();
        i.onload = function() {
            imgDimensions.width = i.naturalWidth;
            imgDimensions.height = i.naturalHeight;
        }

        i.src = `data:image/jpeg;base64,${buffer}`;
    }

    this.getElement = () => {
        return screen;
    }

    this.init = (screenSize, rate) => {
        restartScreen();
        if (typeof rate != 'undefined') scope.rate = rate;

        screen.style.width = screenSize.width + 'px';
        screen.style.height = screenSize.height + 'px';

        dimensions.width = screen.style.width;
        dimensions.height = screen.style.height;

        stop = false;

        thick();
    };

    this.stop = () => {
        stop = true;
        restartScreen();
    }

    this.close = () => {
        scope.end();
        restartScreen();
    }

    this.play = () => {
        stop = false;
        thick();
    }

    this.request = () => {}

    this.on = events.addEventListener

    function thick() {
        if (!stop) {
            scope.request({ quality: scope.quality });
            setTimeout(thick, parseInt(60000 / scope.rate));
        }
    }

    function restartScreen() {
        screen.style.backgroundSize = "24px";
        screen.style.backgroundImage = "url('assets/img/ic_cast.png')";
    }

    function downloadPictureHandler() {
        const imageURL = screen.style.backgroundImage.slice(4, -1).replace(/"/g, "");
        const imageFilename = `scr_${andy.device.device.name}@${andy.backend.hostname}_${new Date().getTime()}`;
        const a = document.createElement("a");
        a.setAttribute("href", imageURL);
        a.setAttribute("download", imageFilename);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    function openRemoteConnectionHandler() {
        remoteBtn.getElement().style.display = 'none';
        remoteBtn.getElement().style.pointerEvents = 'none';
        pictureBtn.getElement().style.display = 'none';
        pictureBtn.getElement().style.pointerEvents = 'none';

        parent = screen.parentElement;

        scope.modal.screenComponent.element.append(screen);
        scope.modal.show();

        const modalSize = scope.modal.getElement().getBoundingClientRect();
        const scale = imgDimensions.width > imgDimensions.height ? modalSize.width / imgDimensions.width : modalSize.height / imgDimensions.height;

        screen.style.width = imgDimensions.width + "px";
        screen.style.height = imgDimensions.height + "px";
        screen.style.transform = `scale(${scale})`;
        screen.style.borderRadius = "0";

        scope.rate = 60;

        screen.addEventListener('click', clickHandler);
    }

    function clickHandler(e) {
        const clickPosition = {
            x: e.offsetX,
            y: e.offsetY
        }

        const previewSize = {
            ...imgDimensions
        }

        const calculatedClickPosition = {
            x: clickPosition.x / previewSize.width,
            y: clickPosition.y / previewSize.height
        }

        events.dispatchEvent('click.request', { clickPosition, calculatedClickPosition, previewSize })
    }

    function ScreenModal() {
        const element = new DIV('remote-component');
        element.style.cssText = "display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;";

        const component = {
            getElement: () => {
                return element;
            },
            get element() {
                return element;
            }
        }

        const modal = new andy.UI.Modal([component]);
        modal.screenComponent = component;
        modal.getElement().style.backgroundColor = "#212121";
        modal.getElement().style.width = "60%";

        modal.on('hide', () => {
            parent.append(screen)
            screen.style.height = dimensions.height;
            screen.style.width = dimensions.width;
            screen.style.transform = `scale(1)`;
            screen.style.borderRadius = "5px";
            scope.rate = 10;
            remoteBtn.getElement().style.display = 'flex';
            remoteBtn.getElement().style.pointerEvents = 'all';
            pictureBtn.getElement().style.display = 'flex';
            pictureBtn.getElement().style.pointerEvents = 'all';
            screen.removeEventListener('click', clickHandler);
        })

        return modal;
    }
}