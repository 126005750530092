export function ModulesListView() {

    const events = new andy.EventManager();

    let modulesForms, modulesGridList;

    function constructor() {

        modulesGridList = new andy.UI.GridList({
            template: moduleTemplate,
        });

        modulesForms = new andy.UI.Form('modules', {
            list: { item: modulesGridList }
        }, {}, {
            search: new andy.UI.RetroSearchBox(modulesGridList),
        });

    }

    function moduleTemplate(item, render) {
        const wrapper = new DIV('item');

        const labelEl = new andy.UI.Label(item.package.label).getElement();
        const icon = new andy.UI.Icon('mdi-puzzle').getElement();

        wrapper.append(icon, labelEl);

        wrapper.addEventListener('click', () => {
            events.dispatchEvent('module.open', item);
        });

        render(wrapper);
        return item;
    }

    constructor();

    return {
        get element() {
            return modulesForms.element;
        },
        get gridList() {
            return modulesGridList;
        },
        on: events.addEventListener
    }
}