export function ProjectListView() {

    const events = new andy.EventManager();

    let gridList, searchBox, projectsListForm;

    function constructor() {
        const sortByDropdown = {
            nameAsc: new andy.UI.Button({ label: 'A-Z', icon: 'mdi-sort-alphabetical-ascending' }, () => gridList.sortItems('nameAscending')),
            nameDesc: new andy.UI.Button({ label: 'Z-A', icon: 'mdi-sort-alphabetical-descending' }, () => gridList.sortItems('nameDescending'))
        };

        gridList = new andy.UI.GridList({
            groupFunction: item => item.group || 'no_group',
            groupTemplate: groupProjectTemplate,
            template: createProjectItemTemplate,
            sort: [
                { label: 'nameAscending', sort: sortAsc },
                { label: 'nameDescending', sort: sortDesc }
            ]
        });

        searchBox = new andy.UI.RetroSearchBox(gridList, { key: 'name' });

        projectsListForm = new andy.UI.Form('projects', {
            list: { item: gridList }
        }, {
            add: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, () => events.dispatchEvent('add')),
        }, {
            search: searchBox,
            filter: new andy.UI.DropDown({ icon: 'mdi-sort', label: 'sort_by', items: sortByDropdown })
        });
    }

    function sortAsc(a, b) {
        if (a.item.name < b.item.name) {
            return -1;
        }
        if (a.item.name > b.item.name) {
            return 1;
        }
        return 0;
    }

    function sortDesc(a, b) {
        if (a.item.name < b.item.name) {
            return 1;
        }
        if (a.item.name > b.item.name) {
            return -1;
        }
        return 0;
    }


    function groupProjectTemplate(code, wrapper) {
        const label = new andy.UI.Label(code)
        const element = new DIV('content');

        const groupUI = new andy.UI.Group({ label }, { style: { justifyContent: 'flex-start', position: 'relative' } });

        const header = new DIV('header');
        header.append(groupUI.getElement());

        let groupWrapper = new DIV('group-wrapper');
        groupWrapper.append(header, element);
        wrapper.append(groupWrapper);

        return { code, label, element };
    }

    const createProjectItemTemplate = (item, render) => {
        let wrapper = new DIV('item');

        const moreDropdownItems = {
            group: new andy.UI.Button({ label: 'change_group', icon: 'mdi-tag-multiple-outline' }, () => events.dispatchEvent('project.group', item)),
            duplicate: new andy.UI.Button({ label: 'duplicate', icon: 'mdi-content-duplicate' }, () => events.dispatchEvent('project.duplicate', item)),
            export: new andy.UI.Button({ label: 'export', icon: 'mdi-folder-download-outline' }, () => events.dispatchEvent('project.export', item)),
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('project.delete', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        const label = new andy.UI.Label(item.name ? item.name : 'item');

        wrapper.addEventListener('click', () => {
            studio.open(item);
        });

        wrapper.append(moreDropdown.getElement(), label.element);
        render(wrapper);
        return item;
    };


    constructor();

    return {
        get element() {
            return projectsListForm.element;
        },
        get gridList() {
            return gridList;
        },
        on: events.addEventListener
    }
}