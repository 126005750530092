export function changeDeviceGroupModal() {

    const events = new andy.EventManager();

    let changeGroupForm;
    let changeGroupModal;
    let groupSelect = new andy.UI.DatalistInput({
        options: []
    });

    function constructor() {
        changeGroupForm = new andy.UI.Form('groups', {
            select: { label: 'select_a_group', item: groupSelect }
        }, {
            save: new andy.UI.Button({ label: 'save', icon: 'mdi-content-save-outline' }, saveHandler)
        });

        changeGroupModal = new andy.UI.Modal(changeGroupForm);
        changeGroupModal.on('dismiss', cancelHandler);
    }

    function saveHandler() {
        const values = { group: changeGroupForm.getValue().select || 'no_group' };
        events.dispatchEvent('save', values); 
    }

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function show() {
        changeGroupModal.show();
    }

    function hide() {
        changeGroupModal.hide();
    }

    constructor();

    return {
        show,
        hide,
        form: changeGroupForm,
        get selector() {
            return groupSelect;
        },
        on: events.addEventListener
    }
}