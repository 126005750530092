import { ProjectListView } from './views/projects.list.view';
import { NewProjectModal } from './views/new.project.modal';
import { DuplicateProjectModal } from './views/duplicate.project.modal';
import { DeleteModal } from './../common/views/delete.modal';
import { ChangeProjectGroupModal } from './views/change.group.modal';

export function ProjectsListController() {

  const projectsDataset = andy.backend.services.projects.dataset;
  const projectsListContainer = andy.mainContainer.newView({ code: 'projects-container', route: '/projects' });

  const changeGroupModal = new ChangeProjectGroupModal();
  const projectListView = new ProjectListView();
  const newProjectModal = new NewProjectModal();
  const deleteProjectModal = new DeleteModal();
  const duplicateProjectModal = new DuplicateProjectModal();// TODO - all duplicate related stuff

  let projectToGroup = null;

  function constructor() {
    addToMainMenu();

    projectListView.on('add', newProjectModal.show);
    projectListView.gridList.bind(projectsDataset);

    newProjectModal.on('new.project', createNewProject);
    newProjectModal.on('import.project', importProjectHandler);

    projectsListContainer.append(projectListView.element);

    projectListView.on('project.delete', (project) => deleteProjectModal.show(project.name, project));
    deleteProjectModal.on('yes', deleteProjectHandler);

    projectListView.on('project.duplicate', (project) => duplicateProjectModal.show(project));
    duplicateProjectModal.on('duplicate', duplicateProjectHandler);

    projectListView.on('project.export', exportHandler);

    projectListView.on('project.group', (project) => {
      projectToGroup = project;
      if (project.group) {
        changeGroupModal.form.setValue({ select: project.group == 'no_group' ? '' : project.group });
      }
      else {
        changeGroupModal.form.setValue({ select: '' });
      }
      changeGroupModal.show();
    })

    changeGroupModal.selector.bind(projectsDataset, 'group');

    changeGroupModal.on('save', ({ group }) => {
      if (projectToGroup && projectToGroup._id) {
        projectsDataset.save({ _id: projectToGroup._id, group });
      }

      changeGroupModal.hide();
    });
  }

  function addToMainMenu() {
    const studioMenuItem = new andy.UI.MainMenuItem({ code: 'applications.studio', label: 'studio', icon: 'mdi-android-studio', order: 0 });
    andy.mainMenu.props.children['root.applications.section'].append(studioMenuItem);

    studioMenuItem.connect(projectsListContainer, 'show', 'select');
  }

  function createNewProject(values) {
    newProjectModal.hide();
    studio.newProject(values);
  }

  function importProjectHandler(project){
      project._id = null;
      studio.importProject(project);
      newProjectModal.hide(); 
  }

  function exportHandler(project) {
    const filename = `${project.name.trim().toLowerCase().split(' ').join('_')}_${Date.now()}.json`;
    exportAsJsonFile(projectsDataset.data[project._id], filename);
  }

  function deleteProjectHandler(project) {
    projectsDataset.remove(project, ({ success }) => {
      deleteProjectModal.hide();
    })
  }

  function duplicateProjectHandler(project) {
    const newProject = { ...project, ...{ _id: null } }
    delete newProject['_id'];

    projectsDataset.save(newProject, ({ success }) => {
      duplicateProjectModal.hide();
    })
  }

  constructor();
  return {

  }
};
