export function ResolutionSelector(p) {

    const events = new andy.EventManager(); 
    const props = {
        ...{
            options: [
                // more common
                { label: 'Full HD', width: '1920', height: '1080' },
                { label: '4K UHD', width: '3840', height: '2160' },
                { label: 'SXGA', width: '1280', height: '1024' },
                { label: 'HD', width: '1280', height: '720' },
                { spacer: true},
                { custom: true},
                { spacer: true},
                // complete list
                // High definition
                { label: 'nHD', width: '640', height: '360' },
                { label: 'qHD', width: '960', height: '540' },
                { label: 'HD', width: '1280', height: '720' },
                { width: '1280', height: '1080' },
                { label: 'HD+', width: '1600', height: '900' },
                { label: 'FHD', width: '1920', height: '1080' },
                { label: 'DCI 2K', width: '2048', height: '1080' },
                { width: '2160', height: '1080' },
                { width: '2560', height: '1080' },
                { label: 'QHD', width: '2560', height: '1440' },
                { label: 'QHD+', width: '3200', height: '1800' },
                { width: '3440', height: '1440' },
                { width: '3840', height: '1080' },
                { width: '3840', height: '1600' },
                { label: '4K UHD', width: '3840', height: '2160' },
                { label: 'DCI 4K', width: '4096', height: '2160' },
                { width: '5120', height: '2160' },
                { label: '5K', width: '5120', height: '2880' },
                { label: '8K UHD', width: '7680', height: '4320' },
                //VGA
                { spacer: true},
                { label: 'QQVGA', width: '160', height: '120' },
                { label: 'HQVGA', width: '240', height: '160' },
                { label: 'QVGA', width: '320', height: '240' },
                { label: 'WQVGA', width: '400', height: '240' },
                { label: 'HVGA', width: '480', height: '320' },
                { label: 'VGA', width: '640', height: '480' },
                { label: 'WVGA', width: '768', height: '480' },
                { label: 'FWVGA', width: '854', height: '480' },
                { label: 'SVGA', width: '800', height: '600' },
                { label: 'DVGA', width: '960', height: '640' },
                { label: 'WSVGA', width: '1024', height: '576' },
                { label: 'WSVGA', width: '1024', height: '600' },
                //XGA
                { spacer: true},
                { label: 'XGA', width: '1024', height: '768' },
                { label: 'WXGA', width: '1366', height: '768' },
                { label: 'WXGA', width: '1360', height: '768' },
                { label: 'WXGA', width: '1280', height: '800' },
                { label: 'XGA+', width: '1152', height: '864' },
                { label: 'WXGA+', width: '1440', height: '900' },
                { label: 'SXGA', width: '1280', height: '1024' },
                { label: 'SXGA+', width: '1400', height: '1050' },
                { label: 'WSXGA+', width: '1680', height: '1050' },
                { label: 'UXGA', width: '1600', height: '1200' },
                { label: 'WUXGA', width: '1920', height: '1200' },
                //QXGA
                { spacer: true},
                { label: 'QWXGA', width: '2048', height: '1152' },
                { label: 'QXGA', width: '2048', height: '1536' },
                { label: 'WQXGA', width: '2560', height: '1600' },
                { label: 'QSXGA', width: '2560', height: '2048' },
                { label: 'WQSXGA', width: '3200', height: '2048' },
                { label: 'QUXGA', width: '3200', height: '2400' },
                { label: 'WQUXGA', width: '3840', height: '2400' }
            ],
            preview: false
        },
        ...p
    }

    const el = new SELECT('andy-ui-select');

    function constructor() {

        props.options.forEach((resolution, i )=> {
            if(resolution.custom){
                addOption('custom', i);
            }else if(resolution.spacer){
                addOption('───────', i, true);
            }else{
                const resStr = `${resolution.width}x${resolution.height} ${(resolution.label) ? '(' + resolution.label + ')' : ''}`;
                addOption(resStr, i);
            }
        });

        el.addEventListener('change', ()=>{
            events.dispatchEvent('change',  props.options[el.value] );
        })

    }

    function addOption(key, value, disabled = false) {
        const optionEl = new OPTION('', key, value);
        if(disabled){
            optionEl.disabled = true; 
        }
        el.append(optionEl);
    }

    function getElement() {
        return el;
    }

    function getValue() {
        return props.options[el.value];
    }

    function setValue(value) {
        el.value = value;
    }

    constructor();

    return {
        get element() {
            return el;
        },
        getElement,
        getValue,
        setValue,
        on: events.addEventListener
    }

}