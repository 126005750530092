export function DatalistInput(p) {

    const events = new andy.EventManager();

    const props = {
        list: `default-${andy.uid.generate()}`,
        type: 'text',
        ...p
    }

    let wrapper, input, datalist;

    function constructor() {
        wrapper = new DIV('andy-ui-datalist-input');

        input = new andy.UI.Input({
            ...props
        });

        datalist = new andy.UI.Datalist({
            id: props.list,
            options: props.options || []
        })

        wrapper.append(input.element, datalist.element);
    }

    function getElement() {
        return wrapper;
    }

    constructor();

    return {
        get element() {
            return wrapper;
        },
        get props() {
            return props;
        },
        set value(v) {
            input.setValue(v);
        },
        get value() {
            return input.value;
        },
        set options(o) {
            datalist.options = [...o];
        },
        get options() {
            return props.options;
        },
        getValue: () => input.value,
        setValue: (v) => input.setValue(v),
        getElement,
        bind: (dataset, key) => datalist.bind(dataset, key),
        on: events.addEventListener
    }
}