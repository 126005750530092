export function NewPageModal() {

    const events = new andy.EventManager();

    let newPageForm, newPageModal;

    function constructor() {


        const orientationSelect = new andy.UI.Select(['horizontal', 'vertical']);

        const resolutionSelect = new andy.UI.ResolutionSelector();

        newPageForm = new andy.UI.Form('new_page', {
            name: { label: 'name', item: new andy.UI.Input({ validators: andy.Validators.notEmpty }) },
            description: { label: 'description', item: new andy.UI.Input() },
            resolution: {label: 'resolution', item: resolutionSelect},
            orientation: {label: 'orientation', item: orientationSelect, filter: 'none'},
            width: { label: 'width', item: new andy.UI.Input({ type: 'number'}), filter: 'customResolution' },
            height: { label: 'height', item: new andy.UI.Input({ type: 'number'}), filter: 'customResolution' }
        }, {
            create: new andy.UI.Button({ label: 'create', icon: 'mdi-check' }, createPageHandler)
        });

        resolutionSelect.on('change', (val)=>{
            if(val.custom){
                newPageForm.applyFilter('customResolution');
            }else{
                newPageForm.applyFilter('none');
            }
        })

        newPageModal = new andy.UI.Modal(newPageForm);
        newPageModal.on('dismiss', cancelHandler);
    }

    function createPageHandler() {
        if (newPageForm.validate()) {
            events.dispatchEvent('new.page', newPageForm.getValue());
        }
    };

    function cancelHandler() {
        events.dispatchEvent('cancel');
    };

    function reset() {
        newPageForm.reset();
        newPageForm.applyFilter('none');
    }

    function dismissable(val){
        newPageModal.props.dismissable = val; 
    }

    function show(page) {
        reset(); 
        if(page){
            newPageForm.setValue(page);
            if(page.resolution){
                if(page.resolution.custom){
                    newPageForm.applyFilter('customResolution');
                }
            }
        }
        newPageModal.show();
    }

    function hide() {
        newPageModal.hide();
        reset();
    }

    constructor();

    return {
        show,
        hide,
        reset,
        dismissable,
        on: events.addEventListener
    }

}