export function CollectionListModal() {

    const treeViewButton = new andy.UI.Button({ icon: 'mdi-file-tree' }, treeViewHandler);
    const events = new andy.EventManager();

    function collectionTemplate(item) {
        let deleteButton = new andy.UI.Button({
            icon: 'mdi-delete',
            label: '',
        }, () => {
            events.dispatchEvent('collection.item.delete', item);
        });

        let editButton = new andy.UI.Button({
            icon: 'mdi-pencil',
            label: ''
        }, () => {
            events.dispatchEvent('collection.item.show', item);
        });

        deleteButton.getElement().style.marginLeft = '2px';
        deleteButton.getElement().style.marginRight = '2px';
        editButton.getElement().style.marginLeft = '2px';
        editButton.getElement().style.marginRight = '2px';

        let name;

        if (item.language && Object.keys(item.language).length > 0) {
            name = item.language[andy.Content.default] ? item.language[andy.Content.default].name : item.language['en_US'].name;
        }

        return new andy.UI.Group({
            icon: new andy.UI.Icon('mdi-file-document'),
            label: new andy.UI.Label(`[${item._id}] ` + name),
            edit: editButton,
            delete: deleteButton
        }).getElement();
    }

    let collectionsList = new andy.UI.List(null, collectionTemplate, { removable: false, draggable: false });

    let collectionComponent = new andy.UI.Form('XYZ', {
        listCollection: {
            label: 'database',
            item: collectionsList
        }
    }, {
        download: new andy.UI.Button({
            icon: 'mdi-cloud-download',
            label: 'download_template'
        }, () => {
            events.dispatchEvent('collection.template')
        }),
        import: new andy.UI.Button({
            icon: 'mdi-cloud-upload',
            label: 'import'
        }, () => {
            events.dispatchEvent('collection.import');
        }),
        add: new andy.UI.Button({
            icon: 'mdi-plus',
            label: 'add'
        }, () => {
            events.dispatchEvent('collection.create');
        })
    }, {
        treeview: treeViewButton
    });

    let collectionsListModal = new andy.UI.Modal(collectionComponent);
    collectionsListModal.getElement().style.width = '80%';

    const treeList = new andy.UI.List(null, treeViewItem, { removable: false, draggable: false })
    const treeViewModal = new andy.UI.Modal([new andy.UI.Form('treeview_preview', {
        list: {
            label: '',
            item: treeList
        }
    })]);

    treeList.getElement().style.padding = 0;
    treeList.getElement().querySelector('ul').style.margin = 0;

    function treeViewHandler() {
        if (!andy.db.vars.selectedDatabase || !andy.db.vars.selectedDatabase.fields.parent) return;

        const list = Object.values(andy._datasets[andy.db.vars.selectedDatabase.code].data);

        if (!list || !list.length) return false;

        const orderedList = buildParentedList(list);

        treeList.clear();
        treeList.add(orderedList, true);
        treeViewModal.show();
    }

    function treeViewItem(item) {
        let name;

        if (item.language && Object.keys(item.language).length > 0) {
            name = item.language[andy.Content.default] ? item.language[andy.Content.default].name : item.language['en_US'].name;
        }

        const label = new andy.UI.Label(name);

        const element = new andy.UI.Group({
            level: !item.parent ? new andy.UI.Icon('mdi-circle-outline') : new andy.UI.Icon('mdi-subdirectory-arrow-right'),
            label
        }).getElement();
        
        element.style.paddingLeft = `${(item.level - 1) * 2}rem`;
        label.getElement().style.marginTop = 0;
        label.getElement().style.marginBottom = 0;

        return element;
    }

    function buildParentedList(arr) {
        const table = {};

        arr.forEach(el => {
            if (!table[el.parent]) table[el.parent] = [];

            table[el.parent].push(el)
        });

        Object.keys(table).forEach((key) => table[key].sort((a, b) => parseInt(a.order) - parseInt(b.order)));

        const result = [];
        
        const build = (key = null, level = 1) => {
            if (!table[key]) return;
        
            table[key].forEach(el => {
                el.level = level;
                result.push(el);
                build(el._id, level + 1);
            })
        }
        
        build();

        return result;
    }

    function toggleTreeView() {
        if (andy.db.vars.selectedDatabase && andy.db.vars.selectedDatabase.fields.parent) {
            treeViewButton.getElement().style.display = 'flex';
        }
        else {
            treeViewButton.getElement().style.display = 'none';
        }
    }

    return {
        component: collectionComponent,
        modal: collectionsListModal,
        list: collectionsList,
        on: events.addEventListener,
        toggleTreeView,
    };
}