export function ExcelParser() {

    const events = new andy.EventManager();
    const fileService = andy.backend.services.files;

    function readTagsFile(file, cb) {
        let tags = [];
        let reader = new FileReader();
        reader.onloadend = function (ev) {
            let arrayBuffer = reader.result;
            let workbook = new ExcelJS.Workbook();
            workbook.xlsx.load(arrayBuffer).then(function (workbook) {
                workbook.worksheets.forEach(function (sheet) {
                    let tag = {
                        language: {}
                    }

                    if (sheet.name === 'General') {
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;
                                tag = {
                                    id: rowValues[1] ? rowValues[1] : null,
                                    gradient: rowValues[2] ? rowValues[2] : null,
                                    color: rowValues[3] ? rowValues[3] : null,
                                    order: rowValues[4] ? rowValues[4] : null,
                                    icon: rowValues[5] ? rowValues[5] : null,
                                    language: {}
                                }

                                tags.push(tag);
                            }
                        });
                    }
                    else {
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;
                                let tagIndex = tags.findIndex(t => t.id === rowValues[1]);
                                if (tagIndex > -1) {
                                    tags[tagIndex].language[sheet.name] = {
                                        name: rowValues[2]
                                    }
                                }
                            }
                        });
                    }
                });

                tags = findImagesForTags(tags);

                if (cb) {
                    cb(tags);
                }
            });
        }

        reader.readAsArrayBuffer(file);
    }

    function findImagesForTags(tags) {
        let medias = Object.values(fileService.dataset.data);

        tags.forEach((tag) => {
            let currentImage = medias.find(m => m.originalName === tag.icon);
            if (currentImage) {
                tag.icon = currentImage._id;
            }
        });

        return tags;
    }

    function readExcelForContent(file, cb) {
        let data = [];
        let reader = new FileReader();
        reader.onloadend = function (ev) {
            let arrayBuffer = reader.result;
            let workbook = new ExcelJS.Workbook();
            workbook.xlsx.load(arrayBuffer).then(function (workbook) {
                workbook.worksheets.forEach(function (sheet) {
                    let dataModel = {
                        language: {},
                        date: {},
                        gps: {},
                        /* tags: [], */
                        contacts: {},
                    }

                    if (sheet.name === 'General') {
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;

                                dataModel = {
                                    id: rowValues[1] ? rowValues[1] : null,
                                    language: {},
                                    accessibility: {},
                                    date: {
                                        startDate: rowValues[2] ? rowValues[2] : null,
                                        endDate: rowValues[3] ? rowValues[3] : null
                                    },
                                    gps: {
                                        lat: rowValues[4] ? rowValues[4] : null,
                                        lng: rowValues[5] ? rowValues[5] : null,
                                    },
                                    /* tags: [], */
                                    favorite: rowValues[16] && rowValues[16] == 'Yes' ? true : false,
                                    price: rowValues[11] ? rowValues[11] : null,
                                    promoPrice: rowValues[12] ? rowValues[12] : null,
                                    quantity: rowValues[13] ? rowValues[13] : null,
                                    rating: rowValues[14] ? rowValues[14] : null,
                                    order: rowValues[15] ? rowValues[15] : null,
                                    contacts: {
                                        email: rowValues[6] ? rowValues[6] : null,
                                        phone: rowValues[7] ? rowValues[7] : null,
                                        website: rowValues[8] ? rowValues[8] : null,
                                        link: rowValues[9] ? rowValues[9] : null,
                                        pdf: rowValues[10] ? rowValues[10] : null,
                                    },

                                    image: rowValues[17] ? rowValues[17] : null,
                                    icon: rowValues[18] ? rowValues[18] : null,
                                    tags: rowValues[19] ? rowValues[19] : null,

                                    gallery: {},
                                    audio: {},
                                    video: {}
                                }

                                data.push(dataModel);
                            }
                        });
                    }
                    else if (sheet.name === 'Galleries') {
                        let galleries = [];
                        let galleryIndex = -1;
                        let postId = -1;
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;

                                if (rowValues[2] != galleryIndex || postId != rowValues[1]) { // gallery already in the array
                                    let currentGallery = {
                                        entryId: rowValues[1],
                                        index: rowValues[2],
                                        media: findMedia(rowValues[3]),
                                        language: {}
                                    }

                                    currentGallery.language[rowValues[4]] = {
                                        title: rowValues[5],
                                        shortDescription: rowValues[6],
                                        longDescription: rowValues[7],
                                        extraDescription: rowValues[8],
                                    }

                                    galleryIndex = rowValues[2];
                                    postId = rowValues[1];

                                    galleries.push(currentGallery);
                                }
                                else {
                                    let currentGallery = galleries.findIndex(g => g.entryId == rowValues[1]);

                                    if (currentGallery >= 0) {
                                        galleries[currentGallery].language[rowValues[4]] = {
                                            title: rowValues[5],
                                            shortDescription: rowValues[6],
                                            longDescription: rowValues[7],
                                            extraDescription: rowValues[8],
                                        }
                                    }
                                }

                                
                            }
                        });

                        galleries.forEach((gallery) => {
                            let entryIndex = data.findIndex(x => x.id == gallery.entryId);
                            
                            if (entryIndex >= 0) {
                                data[entryIndex].gallery[gallery.index] = gallery;
                                delete data[entryIndex].gallery[gallery.index].entryId;
                                delete data[entryIndex].gallery[gallery.index].index;
                            }
                        });
                    }
                    else if (sheet.name === 'Videos') {
                        let videos = [];
                        let videoIndex = -1;
                        let postId = -1;
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;

                                if (rowValues[2] != videoIndex || postId != rowValues[1]) { // video already in the array
                                    let currentVideo = {
                                        entryId: rowValues[1],
                                        index: rowValues[2],
                                        media: findMedia(rowValues[3]),
                                        language: {}
                                    }

                                    currentVideo.language[rowValues[4]] = {
                                        title: rowValues[5],
                                        shortDescription: rowValues[6],
                                        longDescription: rowValues[7],
                                        extraDescription: rowValues[8],
                                    }

                                    videoIndex = rowValues[2];
                                    postId = rowValues[1];

                                    videos.push(currentVideo);
                                }
                                else {
                                    let currentVideo = videos.findIndex(g => g.entryId == rowValues[1]);

                                    if (currentVideo >= 0) {
                                        videos[currentVideo].language[rowValues[4]] = {
                                            title: rowValues[5],
                                            shortDescription: rowValues[6],
                                            longDescription: rowValues[7],
                                            extraDescription: rowValues[8],
                                        }
                                    }
                                }
                            }
                        });

                        videos.forEach((video) => {
                            let entryIndex = data.findIndex(x => x.id == video.entryId);
                            
                            if (entryIndex >= 0) {
                                data[entryIndex].video[video.index] = video;
                                delete data[entryIndex].video[video.index].entryId;
                                delete data[entryIndex].video[video.index].index;
                            }
                        });
                    }
                    else if (sheet.name === 'Audios') {
                        let audios = [];
                        let audioIndex = -1;
                        let postId = -1;
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;

                                if (rowValues[2] != audioIndex || postId != rowValues[1]) { // audio already in the array
                                    let currentAudio = {
                                        entryId: rowValues[1],
                                        index: rowValues[2],
                                        media: findMedia(rowValues[3]),
                                        language: {}
                                    }

                                    currentAudio.language[rowValues[4]] = {
                                        title: rowValues[5],
                                        shortDescription: rowValues[6],
                                        longDescription: rowValues[7],
                                        extraDescription: rowValues[8],
                                    }

                                    audioIndex = rowValues[2];
                                    postId = rowValues[1];

                                    audios.push(currentAudio);
                                }
                                else {
                                    let currentAudio = audios.findIndex(g => g.entryId == rowValues[1]);

                                    if (currentAudio >= 0) {
                                        audios[currentAudio].language[rowValues[4]] = {
                                            title: rowValues[5],
                                            shortDescription: rowValues[6],
                                            longDescription: rowValues[7],
                                            extraDescription: rowValues[8],
                                        }
                                    }
                                }
                            }
                        });

                        audios.forEach((audio) => {
                            let entryIndex = data.findIndex(x => x.id == audio.entryId);
                            
                            if (entryIndex >= 0) {
                                data[entryIndex].audio[audio.index] = audio;
                                delete data[entryIndex].audio[audio.index].entryId;
                                delete data[entryIndex].audio[audio.index].index;
                            }
                        });
                    }
                    else {
                        sheet.eachRow(function (row, rowNumber) {
                            if (rowNumber > 1) {
                                let rowValues = row.values;
                                let dataIndex = data.findIndex(t => t.id === rowValues[1]);
                                if (dataIndex > -1) {
                                    data[dataIndex].language[sheet.name] = {
                                        name: rowValues[2],
                                        title: rowValues[3],
                                        shortDescription: rowValues[4],
                                        longDescription: rowValues[5],
                                        extraDescription: rowValues[6],
                                    }

                                    data[dataIndex].accessibility[sheet.name] = {
                                        name: rowValues[7],
                                        title: rowValues[8],
                                        shortDescription: rowValues[9],
                                        longDescription: rowValues[10],
                                        extraDescription: rowValues[11],
                                    }
                                }
                            }
                        });
                    }
                });

                data = findTags(data);

                data = findImage(data);

                data = findIcon(data);

                data = findPdf(data);

                if (cb) {
                    cb(data);
                }
            });
        }

        reader.readAsArrayBuffer(file);
    }

    function findTags(data) {
        let myDbTags = andy.db.vars.selectedDatabase.tags;

        data.forEach((item) => {
            let actualTags = [];

            let tagIds = item.tags ? item.tags.toString().replace(/ /g, '').split(',') : [];

            tagIds.forEach((tag) => {
                let actualTag = myDbTags.find(t => t.id === tag);
                if (actualTag) {
                    actualTags.push(actualTag);
                }
            });

            item.tags = actualTags;
        });

        return data;
    }

    function findImage(data) {
        let medias = Object.values(fileService.dataset.data);

        data.forEach((item, index) => {
            let currentImage = medias.find(m => m.originalName === item.image);
            if (currentImage) {
                data[index].image = currentImage._id;
            }
        });

        return data;
    }

    function findIcon(data) {
        let medias = Object.values(fileService.dataset.data);

        data.forEach((item, index) => {
            let currentIcon = medias.find(m => m.originalName === item.icon);
            if (currentIcon) {
                data[index].icon = currentIcon._id;
            }
        });

        return data;
    }

    function findMedia(filename) {
        let medias = Object.values(fileService.dataset.data);

        let currentMedia = medias.find(m => m.originalName === filename);

        return currentMedia ? currentMedia : null;
    }

    function findPdf(data) {
        let medias = Object.values(fileService.dataset.data);

        data.forEach((item, index) => {
            let currentPdf = medias.find(m => m.originalName === item.pdf);
            if (currentPdf) {
                data[index].pdf = currentPdf._id;
            }
        });

        return data;
    }

    return {
        readTagsFile,
        readExcelForContent
    }
}