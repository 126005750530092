export function FileSelectModal(options) {

    const events = new andy.EventManager();

    let filesGridList, gfuForm, filesListModal;

    function constructor() {

        filesGridList = new andy.UI.GridList({
            template: fileTemplate,
            groupFunction: groupByType,
            mode: 'select',
        });

        gfuForm = new andy.UI.Form('files', {
            grid: { item: filesGridList }
        }, {
            select: new andy.UI.Button({ label: 'select', icon: 'mdi-check' }, selectHandler),
            cancel: new andy.UI.Button({ label: 'cancel', icon: 'mdi-cancel' }, closeHandler)
        }, {
            search: new andy.UI.RetroSearchBox(filesGridList)
        });

        filesListModal = new andy.UI.Modal([gfuForm], options);
    }


    function selectHandler() {
        events.dispatchEvent('select', { values: filesGridList.getValue() });
    }

    function closeHandler() {
        events.dispatchEvent('modal.close');
    }

    function fileTemplate(item, render) {
        let wrapper = new DIV('gfu-file-item');

        if (item.type) {
            wrapper.classList.add(item.type);
        }

        if (item.type) {
            item.type = item.type.split('/')[0];
        }

        let svgIcon = '';
        if (item.type == 'image'/*  && item.filename.split('.').pop().indexOf('svg') > -1 */) {
            svgIcon = new andy.UI.Icon('mdi-file-image-outline');
        }
        else if (item.type == 'application' || item.type == 'document') {
            svgIcon = new andy.UI.Icon('mdi-file-outline');
        }
        else if (item.type == 'audio') {
            svgIcon = new andy.UI.Icon('mdi-file-music-outline');
        }
        else if (item.type == 'video') {
            svgIcon = new andy.UI.Icon('mdi-file-video-outline')
        }
        else {
            svgIcon = new andy.UI.Icon('mdi-file-alert-outline');
        }

        if (svgIcon != '' && svgIcon.getElement) {
            svgIcon.getElement().style.fontSize = "90px";
            svgIcon.getElement().style.opacity = 0.1;
        }

        if (item.type == 'image' || item.type == 'video') {
            if (window.location.host.indexOf('http:') > -1 || window.location.host.indexOf('https:') > -1) {
                testImage(`${andy.backend.location}/thumbnails/${item.thumbnail}`, (url, success) => {
                    if (success) {
                        wrapper.style.backgroundImage = `url('${andy.backend.location}/thumbnails/${item.thumbnail}')`;
                    }
                    else {
                        if (svgIcon != '' && svgIcon.getElement) {
                            wrapper.append(svgIcon.getElement());
                        }
                    }
                })

            }
            else {
                testImage(`${andy.backend.location}/thumbnails/${item.thumbnail}`, (url, success) => {
                    if (success) {
                        wrapper.style.backgroundImage = `url('${andy.backend.location}/thumbnails/${item.thumbnail}')`;
                    }
                    else {
                        if (svgIcon != '' && svgIcon.getElement) {
                            wrapper.append(svgIcon.getElement());
                        }
                    }
                });
            }
        }
        else {
            if (svgIcon != '' && svgIcon.getElement) {
                wrapper.append(svgIcon.getElement());
            }
        }

        let label = new andy.UI.Label((item.name) ? item.name : 'item');

        wrapper.append(label.getElement());
        render(wrapper);
        return item;
    }

    function groupByType(item) {
        if (item.type) {
            const type = item.type.split('/');
            if (type.length) {
                return type[0];
            }
        }
        return 'document';
    }

    function testImage(url, callback, timeout) {
        timeout = timeout || 5000;
        var timedOut = false, timer;
        var img = new Image();
        img.onerror = img.onabort = function () {
            if (!timedOut) {
                clearTimeout(timer);
                callback(url, false);
            }
        };
        img.onload = function () {
            if (!timedOut) {
                clearTimeout(timer);
                callback(url, true);
            }
        };
        img.src = url;
        timer = setTimeout(function () {
            timedOut = true;
            callback(url, false);
        }, timeout);
    }


    function setOptions(options){
        filesGridList.changeMode((options.mode) ? options.mode : 'select');
    }

    constructor();

    return {
        get element() {
            return filesListModal.getElement();
        },
        get filesGridList() {
            return filesGridList;
        },
        setOptions, 
        getElement() {
            return filesListModal.getElement();
        },
        show() {
            filesListModal.show();
        },
        hide() {
            filesListModal.hide();
        },
        on: events.addEventListener
    }
}

