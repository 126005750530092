// Components to extend the framework

import { FontSelector } from './ui/fontselector/fontselector';
import { MainMenu } from './ui/mainmenu/mainmenu';
import { Section } from './ui/mainmenu/section';
import { Item } from './ui/mainmenu/item';
import { Avatar } from './ui/avatar/avatar';
import { SectionBar } from './ui/sectionbar/bar';
import { UploadsList } from './ui/uploadslist/uploadsList';
import { GradientPicker } from './ui/databases/gradientPicker';
import { TagSelector } from './ui/databases/tagsSelector';
import { ZoomInput } from './ui/zoominput/zoominput';
import { StudioList } from './ui/studiolist/studiolist';
import { StudioListItem } from './ui/studiolist/item';
import { ResolutionSelector} from './ui/resolutionselector/resolutionselector';
import { FullCalendar } from './ui/calendar/calendarnew';
import { LanguageObject } from './ui/languageobject/andy.ui.languageobject'
import { GFMSelect } from './ui/gfmselect/andy.ui.gfmselect';
import { FileImport } from './ui/fileimport/fileimport';
import { DatalistInput } from './ui/datalistinput/datalistinput';

if (window.andy && window.andy.UI) {
        window.andy.UI.FontSelector = FontSelector;
        window.andy.UI.MainMenu = MainMenu;
        window.andy.UI.MainMenuSection = Section;
        window.andy.UI.MainMenuItem = Item;
        window.andy.UI.Avatar = Avatar;
        window.andy.UI.SectionBar = SectionBar;
        window.andy.UI.UploadsList = UploadsList;
        window.andy.UI.GradientPicker = GradientPicker;
        window.andy.UI.TagSelector = TagSelector;
        window.andy.UI.ZoomInput = ZoomInput;
        window.andy.UI.StudioList = StudioList;
        window.andy.UI.StudioListItem = StudioListItem;
        window.andy.UI.ResolutionSelector = ResolutionSelector;
        window.andy.UI.Calendar = FullCalendar;
        window.andy.UI.LanguageObject = LanguageObject;
        window.andy.UI.GFMSelect = GFMSelect;
        window.andy.UI.FileImport = FileImport;
        window.andy.UI.DatalistInput = DatalistInput;
}