
export function UsersListView() {

    const events = new andy.EventManager();

    let usersForm, usersGridList;

    function constructor() {

        usersGridList = new andy.UI.GridList({
            template: accountTemplate
        });

        usersForm = new andy.UI.Form('Accounts', {
            list: { label: '', item: usersGridList }
        }, {
            add: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, () => events.dispatchEvent('add'))
        }, {
            search: new andy.UI.RetroSearchBox(usersGridList, { key: 'name' })
        });

    }

    function accountTemplate(item, render) {
        let wrapper = new DIV('user-item');

        const moreDropdownItems = {
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('account.delete', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        let label = new andy.UI.Label((item.name) ? item.name : 'item');

        wrapper.addEventListener('click', () => events.dispatchEvent('account.edit', item));

        wrapper.append(label.getElement(), moreDropdown.getElement());
        render(wrapper);
        return item;
    }

    constructor();

    return {
        get usersGridList() {
            return usersGridList;
        },
        get element() {
            return usersForm.element;
        },
        on: events.addEventListener
    }
}