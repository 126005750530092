export function UploadController() {

    const events = new andy.EventManager();

    const uploadService = andy.backend.services.upload;
    const filesService = andy.backend.services.files;

    let uploadEl;

    //TODO - Handle Error event and Cancel upload


    function constructor() {

        uploadEl = document.createElement('input');
        uploadEl.type = 'file';
        uploadEl.multiple = "multiple";

        uploadEl.onchange = function () {
            uploadService.submitFiles(this.files);
            this.value = "";
        };

        uploadService.on('start', uploadStartHandler)
        uploadService.on('progress', uploadProgressHandler);
        uploadService.on('complete', uploadCompleteHandler);
    }

    function uploadStartHandler({ file }) {
        let _file; 
        
        if (!file.type) {
            _file = {
                meta: file.meta,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                webkitRelativePath: '',
                type: andy.Utils.typeFromExtension(file.name)
            }
        }
        else {
            _file = file;
        }

        return filesService.preInsertFile(_file, ({ success, value }) => {
            if (!success) {
                console.error('Error at presave the file');
                return false;
            }
            file.meta._id = value._id;
            events.dispatchEvent('file.start', file, value);
            return true;
        });
    }

    function uploadProgressHandler(event) {
        const {file, bytesLoaded} = event;
        events.dispatchEvent('file.progress', {file, bytesLoaded});
        return false;
    }

    function uploadCompleteHandler({ file, detail }) {
        console.log(file, detail);
        filesService.insertFile(file, detail, (res) => {
            events.dispatchEvent('file.complete', file, res);
        });
    }

    function triggerUploader() {
        uploadEl.click();
    }

    constructor();

    return {
        triggerUploader,
        listenOnDrop: uploadService.listenOnDrop,
        on: events.addEventListener
    }
}
