export function DirectDeviceInstance(p) {

    const events = new andy.EventManager();
    const directDevice = andy.backend.services.directDevice;

    let device = null;
    let connected = false;
    let firstConnect = true;
    let connectionTimeout = null;
    let attempt = 0;
    let lastPacket = Date.now();
    let packetInterval = null;
    let toClose = false;

    const props = {
        ...{
            autoConnect: true,
            maxLiveConnectionAttempts: 3,
            liveConnectionTimeout: 10000,
            maxPacketInterval: 4000,
            pause: false,
        },
        ...p
    }

    function constructor() {

        directDevice.on('device.details', (data) => {
            receivedData(data);
            events.dispatchEvent('device.details', data);
        });

        directDevice.on('cpu.usage', (data) => {
            receivedData(data);
            events.dispatchEvent('cpu.usage', data);
        });

        directDevice.on('free.memory', (data) => {
            receivedData(data);
            events.dispatchEvent('free.memory', data);
        });

        directDevice.on('screenshot', (data) => {
            receivedData(data);
            events.dispatchEvent('screenshot', data);
        });

        directDevice.on('uptime', (data) => {
            receivedData(data);
            events.dispatchEvent('uptime', data);
        });

        directDevice.on('terminal.info', (data) => {
            receivedData(data);
            events.dispatchEvent('terminal.info', data);
            if(!data.history.length){
                terminalOpen();
            }else{
                terminalWrite('clear\n');
            }
        });

        directDevice.on('terminal.data', (data) => {
            receivedData(data);
            events.dispatchEvent('terminal.data', data);
        });
    }

    function startLiveConnection() {
        connectionTimeout = setTimeout(connectionTimeoutError, props.liveConnectionTimeout);
        events.dispatchEvent('attempt.connect', attempt);
        directDevice.subscribe(device._id);
        directDevice.reqDeviceDetails(device._id);
        directDevice.reqTerminalInfo(device._id);
    }

    function stopLiveConnection() {
        events.dispatchEvent('disconnected');
        terminalClose();
        directDevice.unsubscribe(device._id);
    }

    function startPacketInterval() {
        if (!packetInterval) {
            packetInterval = setInterval(() => {
                if (Date.now() - lastPacket > props.maxPacketInterval) {
                    clearInterval(packetInterval);
                    if (device) {
                        packetInterval = null;
                        connected = false;
                        attempt = 0;
                        stopLiveConnection();
                        setTimeout(startLiveConnection, 1000);
                    }
                }
            }, 1000);
        }
    }

    function receivedData(data) {
        if (toClose) return;
        lastPacket = Date.now();
        if (connectionTimeout) {
            clearTimeout(connectionTimeout);
            connectionTimeout = null;
        }
        if (!connected) {
            connected = true;
            if (firstConnect) {
                firstConnect = false;
                events.dispatchEvent('connected', data);
            } else {
                events.dispatchEvent('reconnected', data);
            }
            startPacketInterval();
        }
    }

    function connectionTimeoutError() {
        if (attempt < props.maxLiveConnectionAttempts) {
            attempt++;
            startLiveConnection();
        } else {
            events.dispatchEvent('connection.failed', { reason: 'timeout' });
        }
    }

    function open(dev) {
        if (device) return 0;

        toClose = false;
        device = dev;

        if (props.autoConnect) {
            startLiveConnection();
        }
    }

    function close() {
        toClose = true;
        if (connected) {
            stopLiveConnection();
        }
        device = null;
        firstConnect = true;
        connected = false;
        attempt = 0;
        if (packetInterval) {
            clearInterval(packetInterval);
            packetInterval = null;
        }
        if (connectionTimeout) {
            clearTimeout(connectionTimeout);
            connectionTimeout = null;
        }
    }

    function requestScreenshot(){
        if (connected) {
            directDevice.reqScreenshot(device._id);
        }
    }

    function requestClick(data) {
        console.log('click.request', data)

        if(connected) {
            directDevice.reqClick(device._id, data);
        }
    }

    function reboot() {
        if (connected) {
            directDevice.reqReboot(device._id);
        }
    }

    function shutdown() {
        if (connected) {
            directDevice.reqShutdown(device._id);
        }
    }

    function sync() {
        if (connected) {
            directDevice.reqAppUpdate(device._id);
        }
    }

    function refresh() {
        if (connected) {
            directDevice.reqAppRefresh(device._id);
        }
    }

    function pause() {
        if (connected) {
            directDevice.reqAppPause(device._id);
        }
    }

    function resume() {
        if (connected) {
            directDevice.reqAppResume(device._id);
        }
    }

    function terminalWrite(data) {
        if (connected) {
            directDevice.reqTerminalWrite(device._id, 0, data);
        }
    }

    function terminalOpen() {
        if (connected) {
            events.dispatchEvent('terminal.open');
            directDevice.reqTerminalOpen(device._id);
        }
    }

    function terminalClose() {
        if (connected) {
            events.dispatchEvent('terminal.close');
            directDevice.reqTerminalClose(device._id, 0);
        }
    }


    constructor();
    return {
        get props() {
            return props;
        },
        open,
        close,
        sync,
        reboot,
        refresh,
        pause,
        resume,
        shutdown,
        terminalWrite,
        requestScreenshot,
        requestClick,
        on: events.addEventListener
    }
}