export function DatabaseFormModal () {

    const events = new andy.EventManager();
    const tagsList = new andy.UI.List(null, tagTemplate, { removable: true, draggable: false });

    tagsList.on('itemRemoved', (item) => {
        events.dispatchEvent('database.tags.remove', item);
    });
   
    function tagTemplate(item) {
        let name;

        if (item.language && Object.keys(item.language).length > 0) {
            name = item.language[andy.Content.default] ? item.language[andy.Content.default].name : item.language['en_US'].name;
        }

        return new andy.UI.Group({
            icon: new andy.UI.Icon('local_offer'),
            label: new andy.UI.Label(name),
            edit: new andy.UI.Button({
                icon: 'mdi-pencil',
                label: ''
            }, () => {
                events.dispatchEvent('database.tags.show', item);
            })
        }).getElement();
    }

    function addTagHandler(e) {
        events.dispatchEvent('database.tags.add');
    }

    function importTagsHandler(e) {
        events.dispatchEvent('database.tags.import');
    }

    function downloadTemplateHandler(e) {
        events.dispatchEvent('database.tags.template');
    }

    // Template Selector
    let preloader = new andy.UI.Select([{
        code: 'custom',
        label: 'custom'
    },{
        code: 'points_of_interest',
        label: 'points_of_interest'
    },{
        code: 'news',
        label: 'news'
    },{
        code: 'events',
        label: 'events'
    }], (option, eOption) => {
        eOption(option.code, option.label);
    });

    preloader.on('change', (e) => {
        events.dispatchEvent('database.preloader.change', e);
    });

    // Form
    let databasesForm = new andy.UI.Form('database', {
        code: {
            label: 'unique_code',
            item: new andy.UI.Input({ placeholder: '1234567abc'})
        },
        language: {
            label: '',
            item: new andy.UI.LanguageObject({
                name: {
                    label: 'name',
                    item: new andy.UI.Input({ placeholder: 'events' })
                }
            })
        },
        tags: {
            label: '',
            item: new andy.UI.Form('tags', {
                list: {
                    label: 'selected_tags_list',
                    item: tagsList
                }
            }, {
                download: new andy.UI.Button({icon: 'mdi-cloud-download', label: 'download_template'}, downloadTemplateHandler),
                import: new andy.UI.Button({ label: 'import', icon: 'mdi-cloud-upload'}, importTagsHandler),
                add: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, addTagHandler)
            })
        }, 
        gradient: {
            label: '',
            item: new andy.UI.GradientPicker(true)
        },
        color: {
            label: 'color',
            item: new andy.UI.ColorPicker(true)
        },
        icon: {
            label: 'icon',
            item: new andy.UI.GFMSelect({ type: 'image' })
        },
        fields: {
            label: '',
            item: new andy.UI.Form('activate_fields', {
                preloader: {
                    label: 'preload_template',
                    item: preloader
                },
                date: {
                    label: '',
                    item: new andy.UI.Form('dates', {
                        startDate: {
                            label: 'start_date',
                            item: new andy.UI.Switch(true)
                        },
                        endDate: {
                            label: 'end_date',
                            item: new andy.UI.Switch(true)
                        }
                    })
                },
                contacts: {
                    label: '',
                    item: new andy.UI.Form('contacts', {
                        email: {
                            label: 'email',
                            item: new andy.UI.Switch(true)
                        },
                        phone: {
                            label: 'phone',
                            item: new andy.UI.Switch(true)
                        },
                        website: {
                            label: 'website',
                            item: new andy.UI.Switch(true)
                        },
                        link: {
                            label: 'link',
                            item: new andy.UI.Switch(true)
                        },
                        pdf: {
                            label: 'pdf',
                            item: new andy.UI.Switch(true)
                        }
                    })
                },
                parent: {
                    label: 'parent',
                    item: new andy.UI.Switch(false)
                },
                language: {
                    label: 'language_fields',
                    item: new andy.UI.Switch(true)
                },
                accessibility: {
                    label: 'accessibility',
                    item: new andy.UI.Switch(true)
                },
                color: {
                    label: 'color',
                    item: new andy.UI.Switch(true)
                },
                gps: {
                    label: 'gps_coordinates',
                    item: new andy.UI.Switch(true)
                },
                favorite: {
                    label: 'favorite',
                    item: new andy.UI.Switch(true)
                },
                price: {
                    label: 'price',
                    item: new andy.UI.Switch(true)
                },
                promoPrice: {
                    label: 'promo_price',
                    item: new andy.UI.Switch(true)
                },
                quantity: {
                    label: 'quantity',
                    item: new andy.UI.Switch(true)
                },
                rating: {
                    label: 'rating',
                    item: new andy.UI.Switch(true)
                },
                order: {
                    label: 'order',
                    item: new andy.UI.Switch(true)
                },
                icon: {
                    label: 'icon',
                    item: new andy.UI.Switch(true)
                },
                image: {
                    label: 'image',
                    item: new andy.UI.Switch(true)
                },
                gallery: {
                    label: 'gallery',
                    item: new andy.UI.Switch(true)
                },
                video: {
                    label: 'video',
                    item: new andy.UI.Switch(true)
                },
                audio: {
                    label: 'audio',
                    item: new andy.UI.Switch(true)
                },
                pdf: {
                    label: 'pdf',
                    item: new andy.UI.Switch(true)
                },
                tags: {
                    label: 'tags',
                    item: new andy.UI.Switch(true)
                }
            })
        }
    }, {
        save: new andy.UI.Button({ label: 'save', icon: 'save' }, savePageHandler)
    });

    // 
    if (databasesForm.items.language) {
        databasesForm.items.language.item.getElement().querySelector('.header').style.display = 'none';
        databasesForm.items.language.item.getElement().querySelector('.footer').style.display = 'none';
        databasesForm.items.language.item.setValue({
            en_US: {},
            pt_PT: {}
        });
    }

    if (databasesForm.items.accessibility) {
        databasesForm.items.accessibility.item.getElement().querySelector('.header').style.display = 'none';
        databasesForm.items.accessibility.item.getElement().querySelector('.footer').style.display = 'none';
        databasesForm.items.accessibility.item.setValue({
            en_US: {},
            pt_PT: {}
        });
    }

    function savePageHandler(e) {
        let values = map(databasesForm.getValue());
        
        if (!validate(values)) {
            return;
        }

        events.dispatchEvent('database.save', { values });
    }

    function validate(values) { // to-do: manual validation of values
        if (values.code.length <= 0) {
            return false;
        }

        if (values.code.indexOf('andy.db.') < 0) {
            values.code = codemap(values.code);
        }
        
        return true;
    }

    function map(values) {
        if (andy.db.vars.selectedDatabase) {
            values._id = andy.db.vars.selectedDatabase._id;
        }

        values.tags = Object.values(values.tags.list);
        
        return values;
    }

    function codemap(code) {
        return 'andy.db.' + code.toLowerCase().replace(/\s/g, "_");
    }

    let databaseModal = new andy.UI.Modal(databasesForm);
    databaseModal.getElement().style.width = '80%';

    return {
        modal: databaseModal,
        form: databasesForm,
        on: events.addEventListener
    };
}