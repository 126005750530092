import { UploadController } from './upload.controller';
import { UploadDropzone } from './views/files.upload.dropzone';
import { FilesListView } from './views/files.list.view';
import { FileSelectModal } from './views/files.select.modal';
import { FilesUploadModal } from './views/files.upload.modal';
import { FileDetailsModal } from './views/files.details.modal';
import { FileDeleteModal } from './views/files.delete.modal';


export function FilesController() {

    const filesDataset = andy.backend.services.files.dataset;

    const filesContainer = andy.mainContainer.newView({ code: 'files-container', route: '/content' });
    const uploader = new UploadController();
    const dropzone = new UploadDropzone();
    const filesListView = new FilesListView();
    const filesUploadModal = new FilesUploadModal();
    const fileDetailsModal = new FileDetailsModal();
    const fileDeleteModal = new FileDeleteModal();
    const fileSelectModal = new FileSelectModal();
    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    let fileSelectModalCallback = null;

    function constructor() {
        addToMainMenu();

        filesListView.filesGridList.bind(filesDataset);
        filesListView.on('upload', filesUploadModal.show);


        //File delete
        filesListView.on('file.delete', fileDeleteHandler);

        fileDeleteModal.on('yes', deleteFile);
        fileDeleteModal.on('cancel', fileDeleteModal.hide);

        //File details
        filesListView.on('file.details', fileDetailsModal.show);
        fileDetailsModal.on('save', saveFileDetails);
        fileDetailsModal.on('cancel', fileDetailsModal.hide);
        fileDetailsModal.on('file.copy.id', copyIdToClipboard);

        filesListView.on('file.download', downloadFile);
        filesListView.on('file.copy.id', copyIdToClipboard);

        filesContainer.append(filesListView.element);

        dropzone.on('drop', filesUploadModal.show);
        uploader.listenOnDrop(dropzone.element);

        filesUploadModal.on('choose.file', uploader.triggerUploader);

        uploader.on('file.start', (file) => filesUploadModal.uploadsList.addFile(file.meta._id, file));
        uploader.on('file.progress', ({ file, bytesLoaded }) => filesUploadModal.uploadsList.updateFile(file.meta._id, bytesLoaded));
        uploader.on('file.complete', (file) => filesUploadModal.uploadsList.completeFile(file.meta._id));

        // File Select Modal

        fileSelectModal.filesGridList.bind(filesDataset);

        fileSelectModal.on('close', () => {
            fileSelectModal.hide();
            if (fileSelectModalCallback) {
                fileSelectModalCallback(null);
                fileSelectModalCallback = null;
            }
        });

        fileSelectModal.on('select', (data) => {
            fileSelectModal.hide();
            if (fileSelectModalCallback) {
                fileSelectModalCallback(data.values);
                fileSelectModalCallback = null;
            }
        });

    }

    function addToMainMenu() {
        const filesMenuItem = new andy.UI.MainMenuItem({ code: 'content.files', label: 'files', icon: 'mdi-folder-outline', order: 1 });
        andy.mainMenu.props.children['root.content.section'].append(filesMenuItem);

        filesMenuItem.connect(filesContainer, 'show', 'select');
    }

    function fileDeleteHandler(file) {
        const searcher = { // Projects, signage, databases and vending
            projects: convertArrayToObject(Object.values(new andy.Dataset('andy.projects').data).map(p => ({
                _id: p._id,
                name: p.name,
                description: p.description,
                pages: p.pages,
            })), '_id'),
            signage: new andy.Dataset('andy.signage.playlists').data,
            databases: {},
            vending: {}
        }

        Object.keys(andy._datasets).forEach((datasetKey) => {
            if (datasetKey.includes('andy.db')) searcher.databases[datasetKey.replace('andy.db.', '')] = andy._datasets[datasetKey].data;
            else if (datasetKey.includes('andy.vm') && !datasetKey.includes('audits')) searcher.vending[datasetKey.replace('andy.vm.', '')] = andy._datasets[datasetKey].data;
        });

        const foundSomewhere = searchValue(searcher, file._id);

        if (!foundSomewhere || !foundSomewhere.length) {
            return fileDeleteModal.show(file);
        }

        console.log('File not deleted, found in: ', foundSomewhere);
        
        const found = foundSomewhere.map(f => ` ${f.split('.')[0].charAt(0).toUpperCase() +  f.split('.')[0].slice(1)}`).filter(unique);
        const label = `${andy.Language.get('file_cannot_be_removed_still_in_use')} ${[...found]}`;

        notifications.new(label, 'error', 7500)
    }

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function saveFileDetails({ file, values }) {
        file.name = values.name;
        file.tags = values.tags;
        filesDataset.save(file, (res) => {
            fileDetailsModal.hide(); //TODO - handle error
        });
    }

    function deleteFile(file) {
        filesDataset.remove(file, (res) => {
            fileDeleteModal.hide(); //TODO - handle error
        });
    }

    function copyIdToClipboard(file) {
        navigator.permissions.query({ name: "clipboard-write" }).then(result => {
            if (result.state == "granted" || result.state == "prompt") {
                navigator.clipboard.writeText(file._id);
            }
        });
    }

    function downloadFile(file) {
        console.log(file);
        let link = document.createElement("a");
        link.target = '_blank';
        link.download = file.originalName;
        link.href = `${andy.backend.location}/data/${file.filename}`;
        link.click();
    }

    function selectFileModal(options, cb) {
        fileSelectModalCallback = cb;
        fileSelectModal.setOptions(options);
        fileSelectModal.show();
    }

    function searchValue(obj, value, keyPath = [], result = []) {
        for (let key in obj) {
            if (obj[key] === value) {
                keyPath.push(key);
                result.push(keyPath.join('.'));
            }
            if (Array.isArray(obj[key]) || (obj[key] !== null && typeof obj[key] === "object")) {
                searchValue(obj[key], value, keyPath.concat(key), result);
            }
        }
        return result;
    }

    constructor();

    return {
        selectFileModal
    }
}