export function dictionaryEN_US() {
    return {
        "pt_PT": "Português (PT)",
        "en_US": "English (US)",
        "es_CO": "Español (CO)",
        "devices": "Devices",
        "layouts": "Layouts",
        "applications": "Applications",
        "modules": "Modules",
        "statistics": "Statistics",
        "system": "System",
        "admin": "Admin",
        "new_layout": "New Layout",
        "layout_name": "Layout Name",
        "layout_description": "Layout Description",
        "width": "Width",
        "height": "Height",
        "create": "Create",
        "edit_area": "Edit Area",
        "area_name": "Area Name",
        "area_description": "Area Description",
        "top": "Top",
        "left": "Left",
        "save": "Save",
        "assign_to_module": "Assign to Module",
        "cancel": "Cancel",
        "save_changes": "Save Changes",
        "save_confirm": "Want to save project changes?",
        "device_properties": "Device Properties",
        "name": "Name",
        "surname": "Surname",
        "description": "Description",
        "search-medias": "Search Media ...",
        "search-playlists": "Search Playlists ...",
        "search-devices": "Search Devices ...",
        "search-layouts": "Search Layouts ...",
        "search-modules": "Search Modules ...",
        "preview": "Preview",
        "enabled": "Enabled",
        "play_duration": "Play Duration (Seconds)",
        "tags": "Tags",
        "upload_media": "Upload Media File",
        "new_playlist": "New Playlist",
        "add": "Add",
        "location": 'Location',
        "country": 'Country',
        "city": "City",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "zipcode": "Zip Code",
        "user": "User",
        "user_profile": "User Profile",
        "user_location": "User Location",
        "user_contacts": "User Contacts",
        "user_credentials": "User Credentials",
        "organization": "Organization",
        "department": "Department",
        "language": "Language",
        "email": "E-Mail",
        "phone": "Phone",
        "mobile": "Mobile Phone",
        "username": "Username",
        "old_password": "Old Password",
        "new_password": "New Password",
        "repeat_password": "Repeat Password",
        "password": "Password",
        "cpu_info": "Processor Information",
        "memory_info": "Memory Information",
        "os_info": "Operating System Information",
        "status": "Status",
        "displays": "Displays",
        "inputs": "Inputs",
        "network": "Network",
        "settings": "Settings",
        "users": "Users",
        "roles": "Roles",
        "role": "Role",
        "keys": "Keys",
        "languages": "Languages",
        "general": "General",
        "platform": "Platform",
        "search_users": "Search Users",
        "new_user": "New User",
        "user_registry_policy": "User Registration Policy",
        "allow_new_users": "Allow user self registration",
        "activate_domain_whitelist": "Activate domain whitelist",
        "allowed_domains": "Allowed domains",
        "max_users": "Maximum users registrations",
        "default_language": "Default Language",
        "active_languages": "Active Languages",
        "search_roles": "Search Roles",
        "new_role": "New Role",
        "role_permissions": "Permissions",
        "terminal": "Terminal",
        "background_color": "Background Color",
        "background_image": "Background Image",
        "properties": "Properties",
        "object_tree": "Objects",
        "tile_size": "Tile Size",
        "snap_to_grid": "Snap objects to grid",
        "set_grid": "Set Grid",
        "memory_usage": "Memory Usage",
        "remote_terminal": "Remote Terminal",
        "assign_playlist": "Assign Playlist",
        "order": "Order",
        "color": "Color",
        "module": "Module",
        "search": "Search...",
        "clock": "Clock",
        "timezone": "Timezone",
        "format_12": "Format 12h",
        "inside_padding": "Inside Padding",
        "text_color": "Text Color",
        "done": "Done",
        "date": "Date",
        "feed": "Feed",
        "image": "Image",
        "social_wall": "Social Wall",
        "signage": "Signage",
        "webview": "Web Browser",
        "weather": "Weather",
        "webview_options": "Web Browser Options",
        "node_api": "Low Level API",
        "plugins": "Plugins",
        "home_button": "Home Button",
        "back_button": "Back Button",
        "forward_button": "Forward Button",
        "refresh_button": "Reload Button",
        "url": "URL",
        "url_input": "URL Input Box",
        "weather_widget": "Weather Widget",
        "city_id": "City",
        "api_key": "API KEY",
        "refresh_rate": "Refresh Rate",
        "theme": "Theme",
        "displays_and_resolutions": "Displays and Resolutions",
        "button": "Button",
        "fonts": "Fonts",
        "keyboard": "Keyboard",
        "position": "Position",
        "size": "Size",
        "repeat": "Repeat",
        "actions": "Actions",
        "click": "On Click",
        "local_data_servers": "Local Data Servers",
        "strict_mode": "Strict Mode",
        "hosts": "Hosts",
        "generate_token": "Generate Token",
        "display_configuration": "Display Configuration",
        "enable": "Enable",
        "rotation": "Rotation",
        "position_top": "Position Top",
        "position_left": "Position Left",
        "mode": "Mode",
        "deregistration_confirm": "Confirm device deregistration",
        "proceed": "Proceed",
        "deregister_direct_msg": "Deregistration will remove device configurations and remote connection. Do you want to continue?",
        "deregister_undirect_msg": "This device seems to be offline. Proceed will only deregister the device on the platform. Do you want to continue?",
        "deregister": "Deregister",
        "registration": "Registration",
        "audio_mute": "Mute browser audio",
        "new_application": "New Application",
        "search_applications": "Search Applications...",
        "moduledata": "Data",
        "key": "Key",
        "layout_saved_error": "Layout not saved!",
        "layout_saved_success": "Layout saved with success",
        "layout_removed_success": "Layout removed with success",
        "layout_removed_error": "Layout not removed",
        "device_start_direct_mode": "You started a live connection with the device",
        "device_end_direct_mode": "Live connection with the device ended",
        "device_connected_info": "A device was connected to the system",
        "device_deregister_success": "Device deregistered with success",
        "device_deregister_error": "Device not deregistered",
        "device_deregister_info": "A device was deregistered",
        "user_saved_success": "User saved with success",
        "button_style": "Button Style",
        "local_data_server": "Local Data Server",
        "device_settings": "Device Settings",
        "add_media_files": "Add Media Files",
        "live_screen": "Live Screen",
        "quizzes": "Quizzes",
        "questions": "Questions",
        "new_quiz": "New Quiz",
        "new_question": "New Question",
        "search_quizzes": "Search Quizzes",
        "search_questions": "Search Questions",
        "quiz": "Quiz",
        "randomize": "Randomize",
        "change_credentials": "Change Credentials",
        "openweathermap": "OpenWeatherMap.org Widget",
        "type": "Type",
        "question": "Question",
        "answers": "Answers",
        "info": "Info",
        "device_disconnected_info": "A device was disconnected.",
        "lock": "Lock",
        "login": "Login",
        "recover_password": "Recover Password",
        "true_or_false": "True or False",
        "multiple_choice": "Multiple Choice",
        "false": "False",
        "true": "True",
        "correct": "Correct Answer",
        "text": "Text",
        "answer": "Answer",
        "add_questions": "Add Questions",
        "number_of_questions": "Number of questions",
        "application_settings": "Application Settings",
        "enable_override": "Enable Override",
        "device_terminal": "Terminal",
        "device_power": "Power Off",
        "device_reboot": "Reboot",
        "device_displays": "Displays",
        "device_inputs": "Inputs",
        "device_network": "Network",
        "users_create": "Create Users",
        "users_delete": "Delete Users",
        "users_credentials": "User Credentials",
        "application_edit": "Edit",
        "application_create": "Create",
        "application_delete": "Remove",
        "layout_edit": "Edit",
        "layout_create": "Create",
        "layout_delete": "Remove",
        "module_edit": "Edit",
        "statistic_view": "View",
        "administrator": "Administration",
        "media_saved_success": "Media File saved with success.",
        "shuffle": "Shuffle",
        "font_size": "Font Size",
        "contain": "Contain",
        "no_repeat": "No Repeat",
        "cover": "Cover",
        "center": "Center",
        "right": "Right",
        "bottom": "Bottom",
        "user_removed_success": "User removed with success.",
        "device_saved_success": "Device saved with success.",
        "sessions_per_device": "Sessions Per Device",
        "interactions_per_device": "Interactions Per Device",
        "time_per_device": "Time Spent Per Device",
        "online_devices": "Online Devices",
        "dashboard": "Dashboard",
        "device_applications": "Device Applications",
        "override": "Override",
        "environment_variables": "Environment Variables",
        "executable_command": "Startup Commands",
        "smtp_email_configuration": "SMTP Email Configuration",
        "host": "Host",
        "port": "Port",
        "ssl": "SSL",
        "tls": "TLS",
        "model": "Model",
        "cores": "Cores",
        "boxShadow": "Shadow",
        "textColor": "Text Color",
        "backgroundColor": "Background Color",
        "border-radius": "Border Radius",
        "shutdown_info": "Shutdown Info",
        "hour": "Hour",
        "invert": "Invert",
        "mute": "Mute",
        "statistics_view": "View Statistics",
        "timeline": "Timeline",
        "watercycle": "Water Cycle",
        "words": "Words",
        "cards": "Cards",
        "tank": "Tank",
        "new_event": "New Event",
        "menu": "Menu",
        "new_menu": "New Menu",
        "parent": "Parent",
        "upload_icon": "Upload Icon",
        "upload_file": "Upload File",
        "change": "Change",
        "show_all": "Show All",
        "edit": "Edit",
        "clear": "Clear",
        "form": "Form",
        "new_form": "New Form",
        "fields": "Fields",
        "code": "Code",
        "icon": "Icon",
        "click_to_upload": "Click to Upload",
        "click_or_drop_to_upload": "Click or Drop to Upload",
        "value": "Value",
        "file": "File",
        "label": "Label",
        "assign_applications": "Assign Applications",
        "platform_languages": "Platform Languages",
        "content_languages": "Content Languages",
        "sound": "Sound",
        "network_info": "Network Info",
        "player_info": "Player Info",
        "volume": "Master Volume",
        "player_upgrade": "Upgrade",
        "version": "Version",
        "deviceLang_saved_success": "Language saved with success",
        "application_saved_success": "Application saved with success",
        "user_role_saved_error": "Role not saved",
        "user_role_saved_success": "Role saved with success",
        "font_saved_error": "Font not saved",
        "font_saved_success": "Font saved with success",
        "settings_saved_success": "Settings saved with success",
        "settings_saved_error": "Settings not saved",
        "font_delete_error": "Font not deleted",
        "font_delete_success": "Font deleted with success",
        "insert_font": "Insert Font",
        "choose_font": "Choose Font",
        "offline_device_notification": "Notification in case of Offline device",
        "downtime": "Downtime",
        "notification": "Notification",
        "assign_languages": "Assign Languages",
        "add_fonts": "Add Fonts",
        "upload": "Upload",
        "new_device": "New Device",
        "insert_code": "Insert Code",
        "uptime_info": "Uptime Info",
        "uptime": "Uptime",
        "default_font": "Default Font",
        "device_requested_error": "Couldn't request device",
        "device_requested_success": "Device requested, please wait for the connection",
        "device_requested_404": "Device code don't exist",
        "no_files": "Without Files",
        "all_files": "All Files",
        "category_videos": "Videos",
        "category_images": "Images",
        "category_audio": "Audio",
        "category_others": "Others",
        "search-files": "Search Files",
        "new_agenda": "New Agenda",
        "scheduler": "Scheduler",
        "select_playlist": "Select Playlist",
        "new-schedule": "New Schedule",
        "all_day": "All Day",
        "weekly": "Weekly",
        "fixed": "Fixed",
        "search-agendas": "Search Agenda",
        "new-playlist": "New Playlist",
        "medias": "Medias",
        "file_detail": "File Detail",
        "media_manager": "Media Manager",
        "agenda": "Agenda",
        "date_hour_not_valid": "Dates or Hours Invalid",
        "topic_remove_success": "Topic deleted with Success",
        "topic_saved_success": "Topic saved with Success",
        "animal_remove_success": "Animal deleted with Success",
        "animal_saved_success": "Animal saved with Success",
        "desc_saved_success": "Description saved with success",
        "new_animal": "New Animal",
        "title": "Title",
        "pt": "PT",
        "eng": "ENG",
        "credits": "Credits",
        "animals": "Animals",
        "animal": "Animal",
        "child": "Child",
        "images": "Images",
        "aka": "AKA",
        "animal_name": "Animal Name",
        "strips": "Strips",
        "new_strip": "New Strip",
        "video": "Video",
        "edit_video": "Edit Video",
        "new_svg": "New SVG",
        "point": "Point",
        "new_point": "New Point",
        "new_topic": "New Topic",
        "time": "Time",
        "point_saved_success": "Point saved with Success",
        "point_remove_success": "Point deleted with Success",
        "strip_remove_success": "Strip deleted with Success",
        "strip_save_success": "Strip saved with Success",
        "family_remove_success": "Family deleted with Success",
        "card_code": "Card Code",
        "number": "Number",
        "region": "Region",
        "zone_overlay": "Zone Overlay",
        "species": "Species",
        "new": "New",
        "species_saved_success": "Species saved with Success",
        "species_remove_success": "Species deleted with Success",
        "application_removed_success": "Application deleted with Success",
        "application_created_success": "Application created with Success",
        "specie": "Specie",
        "start": "Start",
        "duration": "Duration",
        "strip": "Strip",
        "id": "ID",
        "topic": "Topic",
        "list": "List",
        "update_info": "Update Info",
        "audioplaylist_saved_error": "Couldn't Save Audio Playlist",
        "audioplaylist_saved_success": "Audio Playlist Saved",
        "audioplaylist_remove_error": "Couldn't Remove Audio Playlist",
        "audioplaylist_remove_success": "Audio Playlist Saved",
        "playlist_saved_error": "Couldn't Save Playlist",
        "playlist_saved_success": "Playlist Saved",
        "playlist_removed_error": "Couldn't Remove Playlist",
        "playlist_removed_success": "Playlist Removed",
        "rss_reader": "Rss Reader",
        "rss_url": "Rss Url",
        "extra_text": "Extra Text",
        "vert_scrol": "Vertical Scroll",
        "interval": "Interval",
        "gfu_saved_error": "File not Saved",
        "gfu_saved_success": "File Saved",
        "gfu_removed_error": "File not Removed",
        "gfu_removed_success": "File Removed",
        "wired_connection": "Wired Connection",
        "ipstatic": "Static IP",
        "ip": "IP",
        "netmask": "Netmask",
        "gateway": "Gateway",
        "dns": "DNS",
        "backgroundBlur": "Background Blur",
        "blur-radius": "Blur Radius",
        "mediaManager": "Media Manager",
        "mediaManager_View": "View",
        "mediaManager_Add": "Add",
        "mediaManager_Delete": "Delete",
        "playlists": "Playlists",
        "grid": "Grid",
        "snap": "Snap",
        "day": "Day",
        "days": "Days",
        "minute": "Minute",
        "minutes": "Minutes",
        "hour": "Hour",
        "hours": "Hours",
        "seconds": "Seconds",
        "assign_layouts": "Assign Layout",
        "add_event": "Add Event",
        "event": "Event",
        "sunday": "Sunday",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "user_saved_credentials_error": "Error saving User credentials",
        "user_saved_credentials_success": "User credentials saved",
        "user_removed_error": "Error removing User",
        "user_removed_success": "User Removed",
        "language_removed_error": "Error removing language",
        "language_removed_success": "Language removed",
        "application_created_error": "Error creating Application",
        "application_removed_error": "Error removing Application",
        "click_to_select": "Click to Select",
        "text_editor": "Text Editor",
        "content": "Content",
        "databases": "Databases",
        "files": "Files",
        "map": "Map",
        "groups": "Groups",
        "add_page": 'Add Page',
        "projects": "Projects",
        "new_page": "New Page",
        "new_project": "New Project",
        "peripherals": "Peripherals",
        "confirm_delete": "Delete Confirmation",
        "windowedit": "Window Mode",
        "select_group": "Select Group",
        "create_group": "Create Group",
        "request_device": "Request Device",
        "request": "Request",
        "duplicate": "Duplicate",
        "delete": "Delete",
        "confirm_delete_message": "Delete this?",
        "yes": "Yes",
        "no": "No",
        "restart_devices": "Restart Devices",
        "confirm": "Confirm",
        "sequence": "Sequence",
        "timeline_color": "Timeline Color",
        "skype": "Skype",
        "hangouts": "Hangouts",
        "profile": "Profile",
        "logout": "Logout",
        "without_timelines": "No timelines are available, please create one first.",
        "without_entries_in_sequence": "No entries in this sequence, please create one first.",
        "new_timeline": "New Timeline",
        "edit_schedule": "Edit Schedule",
        "start_date": "Start Date",
        "end_date": "End Date",
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December",
        "unique_code": "Unique Code",
        "add_tags": "Add Tags",
        "selected_tags_list": "List of Selected Tags",
        "download_template": "Download Template",
        "import": "Import",
        "gradient": "Gradient",
        "gradient_picker": "Gradient Picker",
        "primary_color": "Primary Color",
        "secondary_color": "Secondary Color",
        "angle": "Angle",
        "percentage": "Percentage",
        "apply": "Apply",
        "gradient_result": "Gradient Result",
        "activate_fields": "Activate/Deactivate Fields",
        "date_fields": "Date Fields",
        "dates": "Dates",
        "contact_fields": "Contact Fields",
        "website": "Website",
        "link": "Link",
        "pdf": "PDF",
        "language_fields": "Language Fields",
        "gps_coordinates": "GPS Coordinates",
        "favorite": "Favorite",
        "price": "Price",
        "promo_price": "Promotional Price",
        "quantity": "Quantity",
        "rating": "Rating",
        "gallery": "Gallery",
        "database": "Database",
        "preload_template": "Preload Template",
        "contacts": "Contacts",
        "add_database": "Add Database",
        "latlng": "LatLng",
        "lat": "Lat",
        "lng": "Lng",
        "import_id": "Import ID",
        "short_description": "Short Description",
        "long_description": "Long Description",
        "extra_description": "Extra Description",
        "data": "Data",
        "back": "Back",
        "custom": "Custom",
        "points_of_interest": "Points of Interest",
        "events": "Events",
        "news": "News",
        "catalog": "Catalog",
        "edit_sequence": "Edit Sequence",
        "price_in_eur": "Price (In Cents)",
        "image": "Image",
        "tags": "Tags",
        "tag": "Tag",
        "tag_specs": "Tag Specifications",
        "icon": "Icon",
        "tag_gradient": "Tag Gradient",
        "primary_color": "Primary Color",
        "secondary_color": "Secondary Color",
        "angle": "Angle",
        "percentage": "Percentage",
        "apply": "Apply",
        "gradient_result": "Gradient Result",
        "order": "Order",
        "show_as_category": "Show as category",
        "luope_categories_separated_by_commas": "Map to Luope Categories (separated by commas)",
        "active": "Active",
        "disabled": "Disabled",
        "is_promo": "Is Discounted",
        "promotion": "Discount Percentage",
        "is_featured": "Is Featured",
        "sugar": "Sugar",
        "requires_user_input": "Requires User Input",
        "is_menu": "Is a Menu",
        "tier_list": "Tier List",
        "tier_specs": "Tier Specifications",
        "instruction": "Instruction",
        "products_on_tier": "Selectable Products for this Tier",
        "edit": "Edit",
        "remove": "Remove",
        "back": "Back",
        "add": "Add",
        "nutritional_semaphore_active": "Enable Nutritional Semaphore",
        "nutritional_semaphore": "Nutritional Semaphore",
        "files": "Files",
        "image": "Image",
        "items": "Items",
        "product_info": "Product Information",
        "green": "Green",
        "yellow": "Yellow",
        "red": "Red",
        "grey": "Grey",
        "virtual_selectors": "Virtual Selectors",
        "operator_form": "Operator Info",
        "machine_name": "Machine Name",
        "operator_name": "Operator Name",
        "website": "Website",
        "contact_phone": "Contact Phone",
        "operator_logo": "Operator Logo",
        "virtual_selector": "Virtual Selector",
        "device": "Device",
        "selector": "Selector",
        "selectors": "Selectors",
        "price": "Price",
        "stock": "Stock",
        "menu_options": "Menu Options",
        "global_settings": "Global Settings",
        "multi_sale": "Multi-Sale",
        "single_sale": "Single Sale",
        "refund_policy": "Refund Policy",
        "full_refund": "Full Refund",
        "percentage_refund": "Partial Refund (in case of Menus)",
        "no_refund": "No Refund",
        "always_work_offline": "Offline",
        "always_work_luope": "Luope",
        "management_mode": "Management Mode",
        "machine_mapping": "Machine Mapping",
        "machine_maps": "Machine Maps",
        "selector_ranges": "Selector Ranges",
        "selector_range": "Selector Range",
        "processed": "Processed",
        "prefix": "Prefix",
        "suffix": "Suffix",
        "from": "From",
        "to": "To",
        "frontend_settings": "Frontend Settings",
        "light": "Light",
        "dark": "Dark",
        "quick_add_products": "Products on 'Quick Add'",
        "featured_on_single_product_page": "Products Featured on Single Product Page",
        "featured": "Featured",
        "home_sections": "Home Sections",
        "video_sections": "Video Sections",
        "fullscreen_video": "Fullscreen Video",
        "home_video": "Home Video",
        "product_page_video": "Product Page Video",
        "modal_video": "Modal Video",
        "audit": "Audit",
        "list_of_sales": "List of all registered sales",
        "uid": "Unique ID",
        "unique_id": "Unique ID",
        "timestamp": "Timestamp",
        "complete_sale_value": "Complete Sale Value",
        "charged_value": "Charged Value",
        "payment_information": "Payment Information",
        "customer_name": "Customer Name",
        "payment_device": "Payment Device",
        "payment_session_counter": "Payment Session Counter",
        "products_list": "Products List",
        "payment_device_settings": "Payment Device Settings",
        "show_on_frontend": "Show on Frontend",
        "kind": "Kind",
        "cross_sell": "Cross-Sell",
        "products": "Products",
        "vending": "Vending",
        "operator": "Operator",
        "local-configuration": "Local Configuration",
        "global-settings": "Global Settings",
        "frontend-settings": "Frontend Settings",
        "device_id": "Device Identifier",
        "local-settings": "Local Settings",
        "vend-devices": "Vend Devices",
        "payment-devices": "Payment Devices",
        "vend_device": "Vend Device",
        "payment-device": "Payment Device",
        "list_of_vend_devices": "Vend Devices List",
        "list_of_payment_devices": "Payment Devices List",
        "payment_devices_on_frontend": "Payment Devices on Frontend",
        "show_hide_payment_devices": "Show/Hide Payment Devices",
        "money": "Money",
        "credit_debit_card": "Credit/Debit Card",
        "mbway": "MBWay",
        "buyon": "Buyon",
        "voucher": "Voucher",
        "alipay": "AliPay",
        "wechatpay": "WeChatPay",
        "product_complements": "Product Complements",
        "product_complement": "Product Complement",
        "product_complement_info": "Product Complement Information",
        "tag_info": "Tag Information",
        "sync_policy": "Sync Policy",
        "cross_selling": "Cross-Sell",
        "control_device": "Control Device",
        "product": "Product",
        "combo": "Combo",
        "virtual_menus": "Virtual Menus",
        "virtual_combos": "Virtual Combos",
        "machine_map": "Machine Map",
        "virtual_product_complements": "Virtual Product Complements",
        "adnetwork": "Adnetwork",
        "automatic_sync": "Automatic Sync of Products and Tags",
        "document": "Document",
        "daily": "Daily",
        "weekly": "Weekly",
        "monthly": "Monthly",
        "yearly": "Yearly",
        "no_repeat": "Don't Repeat",
        "audio": "Audio",
        "sort_by": "Sort By",
        "request_device_code_description": "Insert the five digit code that appears below the QR code in the player screen.",
        "hostname": "Hostname",
        "add_to_group": "Add to group",
        "change_group": "Change group",
        "resume": "Resume",
        "pause": "Pause",
        "sync": "Sync",
        "refresh": "Refresh",
        "reboot": "Reboot",
        "power_off": "Power Off",
        "sync_group": "Sync Group",
        "refresh_group": "Refresh Group",
        "reboot_group": "Reboot Group",
        "power_off_group": "Power Off Group",
        "delete_group": "Delete Group",
        "no_group": "No Group",
        "connected": "Connected",
        "disconnected": "Disconnected",
        "remote_actions": "Actions",
        "device_projects": "Assigned Studio Projects",
        "project": "Project",
        "studio_applications": "Studio Applications",
        "schedule_shutdown": "Schedule Shutdown",
        "schedule_sync": "Schedule Sync",
        "no_commands_list_placeholder": "Without startup commands",
        "no_device_projects_list_placeholder": "Without projects assigned",
        "accounts": "Accounts",
        "file_details": "File Details",
        "original_name": 'Original File Name',
        "uploaded": "Uploaded",
        "download": "Download",
        "attempt_to_connect": "Attempt to connect...",
        "choose_file": "Choose File",
        "files_upload": "Files Upload",
        "drop_files_to_upload": "Drop files to upload!",
        "complete": "Complete",
        "resolution": "Resolution",
        "orientation": "Orientation",
        "page_settings": "Page Settings",
        "project_settings": "Project Settings",
        "master_page": "Master Page",
        "master_page_description": "When a page is set as Master Page, it's components will appear always on screen.",
        "page_code_description": "Some components could require the page code to perform page change actions. The code should the be unique in the project scope.",
        "create_new_database": "Create new database",
        "duplicate_project": "Duplicate Project",
        "select_a_group": "Select a group",
        "new_group": "New Group",
        "without_info_to_show": "There are no info to show here at the moment.",
        "studio": "Studio",
        "new_sequence": "New Sequence",
        "week_day_monday_short": "Mon",
        "week_day_tuesday_short": "Tue",
        "week_day_wednesday_short": "Wed",
        "week_day_thursday_short": "Thu",
        "week_day_friday_short": "Fri",
        "week_day_saturday_short": "Sat",
        "week_day_sunday_short": "Sun",
        "january_short": "Jan",
        "february_short": "Feb",
        "march_short": "Mar",
        "april_short": "Apr",
        "may_short": "May",
        "june_short": "Jun",
        "july_short": "Jul",
        "august_short": "Aug",
        "september_short": "Sep",
        "october_short": "Oct",
        "november_short": "Nov",
        "december_short": "Dec",
        "recurrent": "Recurrent",
        "count": "Count",
        "frequency": "Frequency",
        "weekday_start": "Weekday",
        "week_days": "Week days",
        "months": "Months",
        "month_days": "Month days",
        "media": "Media",
        "triggers": "Triggers",
        "triggers_list": "List of triggers",
        "create_trigger_at_current_time": "Create trigger at current time",
        "clear_all_triggers": "Clear all triggers",
        "trigger": "Trigger",
        "architecture": "Architecture",
        "model": "Model",
        "cores": "Cores",
        "export": "Export",
        "until": "Until",
        "opacity": "Opacity",
        "style": "Style",
        "border_size": "Border Size",
        "blur": "Blur",
        "grayscale": "Grayscale",
        "saturation": "Saturation",
        "constrast": "Contrast",
        "brightness": "Brightness",
        "hue": "Hue",
        "invert": "Invert",
        "sepia": "sepia",
        "calendar": "Calendar",
        "copy_id_clipboard": "Copy Id to clipboard",
        "accessibility": "Accessibility",
        "simplified_name": "Simplified name",
        "simplified_title": "Simplified title",
        "simplified_short_description": "Simplified short description",
        "simplified_long_description": "Simplified long description",
        "simplified_extra_description": "Simplified extra description",
        "no_data_to_show": "No data to show",
        "content_apis": "Content APIs",
        "google_key": "Google Key",
        "application": "Application",
        "font": "Font",
        "spotside-web-ad": "Spotside Web Ad",
        "unknown": "Unknown",
        "treeview_preview": "Tree preview",
        "treeview": "Tree view",
        "file_cannot_be_removed_still_in_use": "This file can't be removed because it is still in use on: "
    }
}