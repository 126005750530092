
export function RolesListView() {

    const events = new andy.EventManager();

    let rolesForm, rolesGridList;

    function constructor() {

        rolesGridList = new andy.UI.GridList({
            template: roleTemplate
        });

        rolesForm = new andy.UI.Form('roles', {
            list: { label: '', item: rolesGridList }
        }, {
            add: new andy.UI.Button({ label: 'add', icon: 'mdi-plus' }, () => events.dispatchEvent('add'))
        }, {
            search: new andy.UI.RetroSearchBox(rolesGridList, { key: 'name' })
        });

    }

    function roleTemplate(item, render) {
        let wrapper = new DIV('role-item');

        const moreDropdownItems = {
            delete: new andy.UI.Button({ label: 'delete', icon: 'mdi-delete-outline' }, () => events.dispatchEvent('role.delete', item))
        };

        const moreDropdown = new andy.UI.DropDown({ icon: 'mdi-dots-vertical', items: moreDropdownItems, customClass: 'device-item-more' });

        let label = new andy.UI.Label((item.name) ? item.name : 'item');

        wrapper.addEventListener('click', () => events.dispatchEvent('role.edit', item));

        wrapper.append(label.getElement(), moreDropdown.getElement());
        render(wrapper);
        return item;
    }

    constructor();

    return {
        get rolesGridList() {
            return rolesGridList;
        },
        get element() {
            return rolesForm.element;
        },
        on: events.addEventListener
    }
}